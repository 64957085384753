/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-success-redirect',
  templateUrl: './success-redirect.page.html',
  styleUrls: ['./success-redirect.page.scss'],
})
export class SuccessRedirectPage implements OnInit {
  constructor(private router: Router, private authService: AuthService) {
    this.authService.user.subscribe((user) => {
      if (user) {
        this.router.navigateByUrl('payment/success');
      }
    });
  }

  async ngOnInit() {
    if (
      this.authService &&
      this.authService.user &&
      this.authService.user.value
    ) {
      this.router.navigateByUrl('payment/success');
    }
  }
}
