/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable eqeqeq */
/* eslint-disable @angular-eslint/use-lifecycle-interface */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/component-class-suffix */
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { EventService } from 'src/app/services/event-service';
import { FileService } from 'src/app/services/file-service';
import { constants } from '../../constants/constants';
import { AuthService } from 'src/app/services/auth.service';
import { FileCoordinates, LocalFile, SimTacCloudSaveDetails, SimTacFont, SimTacMap, SimTacMapDetails } from 'src/app/models/local-files';
import { SimTacDigitalProfile, SimTacUser } from 'src/app/models/firestore-models';
import { PasswordModal } from '../password-modal/password-modal';
import { Directory } from '@capacitor/filesystem';
import { Camera, CameraResultType } from '@capacitor/camera';
import write_blob from 'capacitor-blob-writer';
import { FirestoreService } from 'src/app/services/firestore.service';
import { MapMetaDataModal } from '../map-meta-data-modal/map-meta-data-modal';
import { Timestamp } from '@angular/fire/firestore';

@Component({
  selector: 'map-select-modal',
  templateUrl: 'map-select-modal.html',
  styleUrls: ['map-select-modal.scss'],
})
export class MapSelectModal {
  @Input() selectMap: any;
  cloudSaves: SimTacCloudSaveDetails[] = [];
  mapNameToImage: Map<string, string> = new Map();
  maps: Map<string, SimTacMap> = new Map();
  passwordSuccess = false;
  userDigitalProfile: SimTacDigitalProfile;
  user: SimTacUser;


  constructor(
    private modalController: ModalController,
    private eventService: EventService,
    private fileService: FileService,
    private router: Router,
    private alertController: AlertController,
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
    private firestoreService: FirestoreService

  ) {}

  ngOnInit() {
    this.userDigitalProfile = this.authService.userDigitalProfile.value;
    this.user = this.authService.user.value;
    this.cloudSaves = this.eventService.cloudSavesList.value;
    this.maps = this.eventService.savedMapList.value;
      this.maps.forEach(async (map) => {
        this.getSource(map.mapDetails.mapName, map.mapImage);
      });
    let saves = this.eventService.cloudSavesList.value;
      this.cloudSaves = saves;
      this.cloudSaves.forEach(async (map) => {
        this.getSource(map.mapName, map.mapImage);
    });
  }

  async openMap(
    map: string,
    isPremiumMap: boolean = false,
    cloudSave?: SimTacCloudSaveDetails,
    customLoaded: boolean = false
  ) {
    const maptemp = this.maps.get(map);
    if (maptemp && maptemp.locked && !this.passwordSuccess) {
      await this.presentPasswordModal(map);
      return;
    }
    this.passwordSuccess = false;
    if (map === 'custom' && !customLoaded) {
      await this.getCustomMap();
      customLoaded = true;
    }
    if (!cloudSave) {
      this.presentMetaDataModal(map, customLoaded);
      return;
    }
    this.eventService.mapType.next(map);
    if (cloudSave) {
      if (cloudSave.mapImage.data.includes('https://')) {
        this.eventService.mapType.next(cloudSave.mapImage.data);
      }
      this.eventService.currentCloudSaveId.next(cloudSave.cloudSaveId);
      this.firestoreService.listenForCloudSaveEvents(this.user.userId);
    }
    this.selectMap(await this.firestoreService.getCloudSaveEvent(this.user.userId));
    this.dismiss();
  }

  async mapContainsKey(key) {
    return this.mapNameToImage.has(key);
  }

  async presentPasswordModal(mapKey: string) {
    const mapPass = this.maps.get(mapKey).mapPassword;
    const modal = await this.modalController.create({
      component: PasswordModal,
      cssClass: 'password-modal',
      componentProps: {
        passwordSuccess: () => {
          this.passwordSuccess = true;
          this.openMap(mapKey);
        },
        currentPassword: mapPass,
      },
    });
    await modal.present();
    return modal;
  }

  async getCustomMap() {
    await Camera.getPhoto({
      resultType: CameraResultType.Base64,
      quality: 10,
    }).then(async (result) => {
      const png = 'data:image/png;base64,' + result.base64String;
      const name =
        this.eventService.getUniqueFileNameKey('uploaded-image') + '.png';
      const w = 0;
      const h = 0;

      const newFile: LocalFile = {
        name,
        fileId: name,
        fileName: name,
        path: name,
        fileCoordinates: new FileCoordinates(0, 0, 0, 0, 0, w, h),
        data: png,
        type: 'image',
        flipped: false,
        flippedFileName: '',
        isPremiumItem: false,
        addOnParentId: '',
        font: new SimTacFont(),
        subText: '',
        subTextFont: new SimTacFont(),
        subTextMirror: false
      };

      const fileBlob = new Blob([JSON.stringify(newFile)], {
        type: 'application/json',
      });
      write_blob({
        // The 'path' option should be a string describing where to write the file. It
        // may be specified as an absolute URL (beginning with "file://") or a relative
        // path, in which case it is assumed to be relative to the 'directory' option.
        path: name,
        // The 'directory' option is used to resolve 'path' to a location on the disk.
        // It is ignored if the 'path' option begins with "file://".

        directory: Directory.Documents,

        // The 'blob' option must be a Blob, which will be written to the file. The file
        // on disk is overwritten, not appended to.

        blob: fileBlob,

        // Fast mode vastly improves read and write speeds on the web platform. For
        // files written with 'fast_mode' set to true, Filesystem.readFile will produce
        // a Blob rather than a Base64-encoded string. The 'fast_mode' option is
        // ignored on iOS and Android. For backwards compatibility, it defaults to
        // false.

        fast_mode: true,

        // If the 'recursive' option is 'true', intermediate directories will be created
        // as required. It defaults to 'false' if not specified.

        recursive: true,

        // If 'write_blob' falls back to its alternative strategy on failure, the
        // 'on_fallback' function will be called with the underlying error. This can be
        // useful to diagnose slow writes. It is optional.

        // See the "Fallback mode" section below for a detailed explanation.

        // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
        on_fallback: (error) => {
          console.error(error);
        },
      }).then(() => {
        console.log('Photo written.');
      });

      // const b2a = btoa(JSON.stringify(newFile));
      // await Filesystem.writeFile({
      //   path: name,
      //   data: b2a,
      //   directory: Directory.Documents,
      // });
      this.eventService.customMapImage.next(newFile);
      return newFile;
    });
  }

  async getSource(mapName, value) {
    if (value.path.includes('assets')) {
      this.mapNameToImage.set(
        mapName,
        '../..' + value.path + '/' + value.fileName
      );
    } else {
      const img = value.data;
      this.mapNameToImage.set(mapName, img);
      this.changeDetectorRef.detectChanges();
    }
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  async presentMetaDataModal(map: string, customLoaded: boolean) {
    const modal = await this.modalController.create({
      component: MapMetaDataModal,
      cssClass: 'map-meta-data-modal',
      componentProps: {
        saveMetaData: async (
          mapName: string,
          mapDescription: string,
          mapAuthor: string,
          authorUrl: string,
          mapLocked: boolean,
          mapPassword: string
        ) => {
          this.eventService.currentMapDetails.next(
            new SimTacMapDetails(mapName, mapAuthor, mapDescription, authorUrl)
          );
          this.eventService.mapLocked.next(mapLocked);
          this.eventService.mapLockPassword.next(mapPassword);
          this.eventService.filesOnScreen = new Map();
          this.eventService.mapType.next(map);
          this.eventService.currentMap.next(null);
          await this.fileService.saveMap();
          this.eventService.currentMapSaved.next(true);
          let mapData = this.eventService.currentMap.value;
          const details: SimTacCloudSaveDetails = new SimTacCloudSaveDetails(
            mapData.mapDetails.mapName,
            mapData.mapDetails.mapAuthor,
            mapData.mapDetails.mapDescription,
            mapData.mapDetails.mapAuthorUrl,
            mapData.userId,
            mapData.cloudSaveId,
            mapData.updatedAt,
            mapData.mapImage
          );
          this.openMap(map, false, details, customLoaded);
        },
        currentMetaData: new SimTacMapDetails(''),
        isMapLocked: this.eventService.mapLocked.value,
        mapLockedPassword: this.eventService.mapLockPassword.value,
        mapType: this.eventService.mapType.value
      },
    });
    await modal.present();
    return modal;
  }
}