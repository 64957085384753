/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, Input } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'create-session-modal',
  templateUrl: 'create-session-modal.html',
  styleUrls: ['create-session-modal.scss'],
})
export class CreateSessionModal {
  @Input() randomUUID: string;
  @Input() sessionDetailsCaptured: any;

  public sessionName: string;
  public sessionAdmins: string[] = [''];
  public sessionViewers: string[] = [''];
  public sessionEditors: string[] = [''];
  public sessionAllUsers: string[] = [''];
  public allUsersMap: Map<string, string> = new Map();

  constructor(
    private modalController: ModalController,
    private alertController: AlertController,
    private authService: AuthService
  ) {}

  checkSessionDetails() {
    this.sessionAllUsers = [];
    this.allUsersMap = new Map();
    this.allUsersMap.set(
      this.authService.user.value.email,
      this.authService.user.value.email
    );

    this.sessionAdmins.forEach((admin) => {
      if (admin && admin !== '') {
        this.allUsersMap.set(admin, admin);
      }
    });

    this.sessionViewers.forEach((viewer) => {
      if (viewer && viewer !== '') {
        this.allUsersMap.set(viewer, viewer);
      }
    });

    this.sessionEditors.forEach((editor) => {
      if (editor && editor !== '') {
        this.allUsersMap.set(editor, editor);
      }
    });

    this.sessionAllUsers = Array.from(this.allUsersMap.values());

    if (this.sessionName === '') {
      this.alertController
        .create({
          message: 'Session must have a name.',
          buttons: [
            {
              text: 'Ok',
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
      return;
    }

    if (this.sessionAllUsers.length <= 1) {
      this.alertController
        .create({
          message: 'Please add users to session before starting.',
          buttons: [
            {
              text: 'Ok',
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
      return;
    }

    if (
      !this.sessionAdmins.find(
        (admin) => admin === this.authService.user.value.email
      )
    ) {
      this.sessionAdmins.push(this.authService.user.value.email);
    }

    this.sessionDetailsCaptured(
      this.sessionName,
      this.sessionAdmins,
      this.sessionViewers,
      this.sessionEditors,
      this.sessionAllUsers,
      this.randomUUID
    );
    this.modalController.dismiss();
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  addNewAdmin() {
    this.sessionAdmins.push('');
  }
  removeAdmin(name) {
    this.sessionAdmins = this.sessionAdmins.filter((admin) => admin !== name);
    if (this.sessionAdmins.length === 0) {
      this.addNewAdmin();
    }
  }

  addNewViewer() {
    this.sessionViewers.push('');
  }

  removeViewer(name) {
    this.sessionViewers = this.sessionViewers.filter(
      (viewer) => viewer !== name
    );
    if (this.sessionViewers.length === 0) {
      this.addNewViewer();
    }
  }

  trackByFn(index: any, item: any) {
    return index;
  }
}
