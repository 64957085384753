/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  LoadingController,
  AlertController,
  ModalController,
} from '@ionic/angular';
import { ForgotPasswordModal } from '../modals/forgot-password-modal/forgot-password-modal';
import { AuthService } from '../services/auth.service';
import { EventService } from '../services/event-service';
import { FirestoreService } from '../services/firestore.service';
import { emailVerified } from '@angular/fire/auth-guard';
import { Timestamp } from '@angular/fire/firestore';
import { SimTacUser } from '../models/firestore-models';

@Component({
  selector: 'app-home',
  templateUrl: 'home.html',
  styleUrls: ['home.scss'],
})
export class HomePage implements OnInit {
  credentials: FormGroup;

  constructor(
    private eventService: EventService,
    private fb: FormBuilder,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private authService: AuthService,
    private router: Router,
    private modalController: ModalController,
    private firestoreService: FirestoreService
  ) {}

  signup() {
    this.router.navigateByUrl('map-selection');
  }

  get email() {
    return this.credentials.get('email');
  }

  get password() {
    return this.credentials.get('password');
  }

  ngOnInit() {
    this.credentials = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  async register() {
    const loading = await this.loadingController.create({
      message: 'Creating account...',
    });
    await loading.present();

    const user = await this.authService.register(this.credentials.value);
    let loggedInUser = new SimTacUser(user.user.uid, user.user.email, user.user.emailVerified, false, Timestamp.now(), false);
    await this.firestoreService.createSimTacUser(loggedInUser);
    await loading.dismiss();

    if (loggedInUser.hasAccess) {
      this.router.navigateByUrl('map-selection', { replaceUrl: true });
    } else {
      this.router.navigateByUrl('no-access', { replaceUrl: true });
    }
  }

  async login() {
    const loading = await this.loadingController.create({
      message: 'Logging in...',
    });
    await loading.present();

    const user = await this.authService.login(this.credentials.value);

    if (user) {
      let loggedInUser = await this.firestoreService.getUserById(user.user.uid);
      if (loggedInUser && loggedInUser.hasAccess) {
        await loading.dismiss();
        this.router.navigateByUrl('map-selection', { replaceUrl: true });
      } else {
        await loading.dismiss();
        this.router.navigateByUrl('no-access', { replaceUrl: true });
      }
    } else {
      await loading.dismiss();
      this.showAlert('Login failed', 'Please try again!');
    }
  }

  async showAlert(header, message) {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: ['OK'],
    });
    await alert.present();
  }

  async openPasswordResetModal() {
    const modal = await this.modalController.create({
      component: ForgotPasswordModal,
      cssClass: 'forgot-password-modal',
      componentProps: {
        onEmailSent: () => {
          this.showAlert('Password Reset', 'Reset Password Sent!');
        },
      },
    });
    await modal.present();
    return modal;
  }
}
