/* eslint-disable @typescript-eslint/no-shadow */
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HomePage } from './home/home';
import { MapSelectionPage } from './map-selection/map-selection';
import { PaymentErrorPage } from './stripe/error/payment-error.page';
import { StripePage } from './stripe/stripe.page';
import { SuccessPage } from './stripe/success/success.page';
import {
  redirectUnauthorizedTo,
  redirectLoggedInTo,
  canActivate,
  emailVerified,
  idTokenResult,
  hasCustomClaim,
} from '@angular/fire/auth-guard';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddOnsPage } from './add-ons/add-ons';
import { SuccessRedirectPage } from './stripe/success-redirect/success-redirect.page';
import { AdminPage } from './admin/admin';
import { AuthService } from './services/auth.service';
import { NoAccessPage } from './no-access/no-access';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['']);
const redirectLoggedInToHome = () => redirectLoggedInTo(['map-selection']);

const routes: Routes = [
  {
    path: 'tabs',
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: 'home',
    component: HomePage,
    ...canActivate(redirectLoggedInToHome),
  },
  {
    path: 'no-access',
    component: NoAccessPage,
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'map-selection',
    component: MapSelectionPage,
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'stripe',
    component: StripePage,
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'payment/success-redirect',
    component: SuccessRedirectPage,
  },
  {
    path: 'payment/success',
    component: SuccessPage,
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'payment/payment-error',
    component: PaymentErrorPage,
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'addons',
    component: AddOnsPage,
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'admin',
    component: AdminPage,
    ...canActivate(redirectUnauthorizedToLogin),
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
