/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, Input } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { SimTacSession } from 'src/app/models/firestore-models';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'invitations-modal',
  templateUrl: 'invitations-modal.html',
  styleUrls: ['invitations-modal.scss'],
})
export class InvitationsModal {
  @Input() invitations: SimTacSession[];
  @Input() connectToSession: any;

  public sessionName: string;

  constructor(
    private modalController: ModalController,
    private alertController: AlertController,
    private authService: AuthService
  ) {}

  startConnectToSession(sessionId: string) {
    this.connectToSession(sessionId);
    this.modalController.dismiss();
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  trackByFn(index: any, item: any) {
    return index;
  }
}
