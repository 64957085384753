/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, LoadingController } from '@ionic/angular';
import { AuthService } from '../services/auth.service';
import { environment } from '../../environments/environment';
import { FirestoreService } from '../services/firestore.service';
import { Timestamp } from '@angular/fire/firestore';
import {
  SimTacPurchase,
  SimTacSubscription,
  SimTacTrial,
  SimTacUser,
} from '../models/firestore-models';
import { constants } from '../constants/constants';
import { formatDate } from '@angular/common';
import { SimTacCloudSaveDetails } from '../models/local-files';
import { EventService } from '../services/event-service';

@Component({
  selector: 'app-admin',
  templateUrl: 'admin.html',
  styleUrls: ['admin.scss'],
})
export class AdminPage implements OnInit {
  loader: any;
  adminAccess = false;
  adminDataLoaded = 0;
  activeSection = 'Dashboard';
  newEmail = null;
  newPassword = null;
  newUserEmail = null;
  newUserPassword = null;
  sortDirectionDesc = true;
  sortColumn = '';

  /* DATA */
  numberOfUsers = 0;
  numberOfUsersObj = [];
  numberOfUsersLast24Hours = 0;
  numberOfUsersLast24HoursObj = [];
  numberOfUsersThisMonth = 0;
  numberOfUsersThisMonthObj = [];
  numberOfActiveSubscriptions = 0;
  numberOfActiveSubscriptionsObj = [];
  numberOfActiveSubscriptionsUsersObj = [];
  numberOfAddOns = 0;
  numberOfAddOnsObj = [];
  numberOfAddOnsUsers = [];
  numberOfTacticalAddOns = 0;
  numberOfTacticalAddOnsObj = [];
  numberOfTacticalAddOnsUsers = [];
  numberOfHospitalAddOns = 0;
  numberOfHospitalAddOnsObj = [];
  numberOfHospitalAddOnsUsers = [];
  numberOfCampusAddOns = 0;
  numberOfCampusAddOnsObj = [];
  numberOfCampusAddOnsUsers = [];
  numberOfFireEmsAddOns = 0;
  numberOfFireEmsAddOnsObj = [];
  numberOfFireEmsAddOnsUsers = [];
  numberOfAddonsBoughtThisMonth = 0;
  numberOfActiveTrials = 0;
  numberOfActiveTrialsUsers = [];
  numberOfActiveTrialsDomains = [];
  numberOfActiveTrialsObj = [];
  numberOfLiveSessionsThisMonth = 0;
  numberOfCloudSaves = [];
  cloudSavesUsers = new Map();
  displayedUserCloudSaves = [];

  currentSimTacVersion = environment.version;
  currentSimTacVersionRestriction = '1.0.0';

  /* INTERACTABLES */
  userSearchQuery = '';
  searchedUsers = [];
  searchedDomains = [];
  searchIsLoading = false;
  displayUserData = false;
  displayDomainData = false;
  displayCloudSaveData = false;
  displayedUser: SimTacUser = null;
  displayedDomain: SimTacTrial = null;

  displayedUserAddons = [];
  displayedUserAddonsHasTactical = false;
  displayedUserAddonsHasHospital = false;
  displayedUserAddonsHasCampus = false;
  displayedUserAddonsHasFireEms = false;
  displayedUserAddonsHasTacticalInitialValue = false;
  displayedUserAddonsHasHospitalInitialValue = false;
  displayedUserAddonsHasCampusInitialValue = false;
  displayedUserAddonsHasFireEmsInitialValue = false;
  displayedUserAddonsLoading = false;

  displayUserSubscriptions: SimTacPurchase[] = null;
  displayedUserSubscriptions: SimTacSubscription = null;
  displayedUserSubscriptionTier = '';
  displayedUserSubscriptionAutoRenews = '';
  displayedUserSubscriptionFrequencys = '';
  displayedUserSubscriptionStartDate = '';
  displayedUserSubscriptionEndDate = '';
  displayedUserSubscriptionsLoading = false;

  displayedUserTrials: SimTacTrial = null;
  displayedUserTrialsStartDate = '';
  displayedUserTrialsEndDate = '';
  displayedUserTrialsDomain = '';
  displayedUserTrialsPremium = false;
  displayedUserTrialsTactical = false;
  displayedUserTrialsHospital = false;
  displayedUserTrialsCampus = false;
  displayedUserTrialsFireEms = false;
  displayedUserTrialsStartDateInitialValue = '';
  displayedUserTrialsEndDateInitialValue = '';
  displayedUserTrialsDomainInitialValue = '';
  displayedUserTrialsPremiumInitialValue = false;
  displayedUserTrialsTacticalInitialValue = false;
  displayedUserTrialsHospitalInitialValue = false;
  displayedUserTrialsCampusInitialValue = false;
  displayedUserTrialsFireEmsInitialValue = false;
  displayedUserTrialsLoading = false;

  domainTrialEmailDomain = '';
  domainTrialStartDate = '';
  domainTrialEndDate = '';
  domainTrialPremium = false;
  domainTrialTactical = false;
  domainTrialHospital = false;
  domainTrialCampus = false;
  domainTrialFireEms = false;

  newSimTacUserEmail = '';
  newSimTacUserPassword = '';

  simTacEmailForPasswordReset = '';

  simTacEmailForSubscription = '';
  simTacSubscriptionTier = '';

  simTacEmailForAddOn = '';
  simTacAddOnId = '';

  simTacAddOnToDisable = '';
  simTacSubscriptionTierToDisable = '';

  simTacVersionEnforcement = '1.0.0';

  constructor(
    private loadingController: LoadingController,
    private alertController: AlertController,
    private router: Router,
    private authService: AuthService,
    private firestoreService: FirestoreService,
    private eventService: EventService
  ) {
    this.authService.user.subscribe((user) => {
      if (user) {
        if (!user.isAdmin) {
          this.adminAccess = false;
          this.router.navigateByUrl('');
          return;
        }
        this.adminAccess = true;
        this.showLoader('Loading Admin Data...', 3000);

        this.getNumberOfActiveUsers();
        this.getNumberOfMonthlyUsers();
        this.getTotalNumberOfUsers();
        this.getTotalNumberOfActiveTrials();
        this.getTotalNumberOfAddOns();
        this.getActiveSubscriptions();
        this.getNumberOfLiveSessionsLast30Days();
        this.getCloudSaves();
      }
    });
  }

  ngOnInit() {
    const user = this.authService.user.value;
    this.adminAccess = user?.isAdmin;
  }

  async showAlert(header, message) {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: ['OK'],
    });
    await alert.present();
  }

  async showLoader(message: string, duration: number) {
    this.loader = await this.loadingController.create({
      message,
      duration,
    });
    this.loader.present();
  }

  async setSection(sectionName: string) {
    this.activeSection = sectionName;
    this.closeUserDetails();
    this.clearDomainFields();
  }

  async goBackToSimTac() {
    const alert = await this.alertController.create({
      header: 'Leave Admin Page',
      message: 'Go back to SimTac?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Yes',
          handler: async () => {
            this.router.navigateByUrl('');
          },
        },
      ],
    });
    await alert.present();
  }

  async getTotalNumberOfUsers() {
    this.numberOfUsersObj = await this.firestoreService.getTotalNumberOfUsers();
    this.numberOfUsers = this.numberOfUsersObj.length;
    this.adminDataLoaded++;
    if (this.adminDataLoaded >= 7) {
      this.loader.dismiss();
    }
  }

  async getNumberOfActiveUsers() {
    this.numberOfUsersLast24HoursObj =
      await this.firestoreService.getActiveNumberOfUsers();
    this.numberOfUsersLast24Hours = this.numberOfUsersLast24HoursObj.length;
    if (this.adminDataLoaded >= 7) {
      this.loader.dismiss();
    }
  }

  async preFillUserDataTable(
    activeUsers,
    activeSection?,
    activeDomains?,
    activeCloudSaveUsers?
  ) {
    if (activeSection) {
      this.activeSection = activeSection;
    } else {
      this.activeSection = 'Users';
    }
    if (activeCloudSaveUsers) {
      this.searchedUsers = Array.from(activeCloudSaveUsers);
    } else {
      this.searchedUsers = activeUsers;
    }
    this.searchIsLoading = false;
    this.displayUserData = false;
    if (activeDomains) {
      this.searchedDomains = activeDomains;
    }
  }

  async sortUserSearchBy(column: string) {
    if (column === 'USERID') {
      if (this.sortColumn === column) {
        this.sortDirectionDesc = !this.sortDirectionDesc;
        this.searchedUsers.sort((a: SimTacUser, b: SimTacUser) => {
          if (this.sortDirectionDesc) {
            if (a.email > b.email) {
              return 1;
            } else {
              return -1;
            }
          } else {
            if (a.email < b.email) {
              return 1;
            } else {
              return -1;
            }
          }
        });
      } else {
        this.sortColumn = column;
        this.searchedUsers.sort((a: SimTacUser, b: SimTacUser) => {
          if (this.sortDirectionDesc) {
            if (a.userId > b.userId) {
              return 1;
            } else {
              return -1;
            }
          } else {
            if (a.userId < b.userId) {
              return 1;
            } else {
              return -1;
            }
          }
        });
      }
    } else if (column === 'EMAIL') {
      if (this.sortColumn === column) {
        this.sortDirectionDesc = !this.sortDirectionDesc;
        this.searchedUsers.sort((a: SimTacUser, b: SimTacUser) => {
          if (this.sortDirectionDesc) {
            if (a.email > b.email) {
              return 1;
            } else {
              return -1;
            }
          } else {
            if (a.email < b.email) {
              return 1;
            } else {
              return -1;
            }
          }
        });
      } else {
        this.sortColumn = column;
        this.searchedUsers.sort((a: SimTacUser, b: SimTacUser) => {
          if (this.sortDirectionDesc) {
            if (a.email > b.email) {
              return 1;
            } else {
              return -1;
            }
          } else {
            if (a.email < b.email) {
              return 1;
            } else {
              return -1;
            }
          }
        });
      }
    } else if (column === 'LOGIN') {
      if (this.sortColumn === column) {
        this.sortDirectionDesc = !this.sortDirectionDesc;
        this.searchedUsers.sort((a: SimTacUser, b: SimTacUser) => {
          if (this.sortDirectionDesc) {
            if (a.lastLogin > b.lastLogin) {
              return 1;
            } else {
              return -1;
            }
          } else {
            if (a.lastLogin < b.lastLogin) {
              return 1;
            } else {
              return -1;
            }
          }
        });
      } else {
        this.sortColumn = column;
        this.searchedUsers.sort((a: SimTacUser, b: SimTacUser) => {
          if (this.sortDirectionDesc) {
            if (a.email > b.email) {
              return 1;
            } else {
              return -1;
            }
          } else {
            if (a.email < b.email) {
              return 1;
            } else {
              return -1;
            }
          }
        });
      }
    }
  }

  async sortDomainSearchBy(column: string) {
    if (column === 'DOMAIN') {
      if (this.sortColumn === column) {
        this.sortDirectionDesc = !this.sortDirectionDesc;
        this.searchedDomains.sort((a: SimTacTrial, b: SimTacTrial) => {
          if (this.sortDirectionDesc) {
            if (a.domain > b.domain) {
              return 1;
            } else {
              return -1;
            }
          } else {
            if (a.domain < b.domain) {
              return 1;
            } else {
              return -1;
            }
          }
        });
      } else {
        this.sortColumn = column;
        this.searchedDomains.sort((a: SimTacTrial, b: SimTacTrial) => {
          if (this.sortDirectionDesc) {
            if (a.domain > b.domain) {
              return 1;
            } else {
              return -1;
            }
          } else {
            if (a.domain < b.domain) {
              return 1;
            } else {
              return -1;
            }
          }
        });
      }
    } else if (column === 'START') {
      if (this.sortColumn === column) {
        this.sortDirectionDesc = !this.sortDirectionDesc;
        this.searchedDomains.sort((a: SimTacTrial, b: SimTacTrial) => {
          if (this.sortDirectionDesc) {
            if (a.startDate > b.startDate) {
              return 1;
            } else {
              return -1;
            }
          } else {
            if (a.startDate < b.startDate) {
              return 1;
            } else {
              return -1;
            }
          }
        });
      } else {
        this.sortColumn = column;
        this.searchedDomains.sort((a: SimTacTrial, b: SimTacTrial) => {
          if (this.sortDirectionDesc) {
            if (a.startDate > b.startDate) {
              return 1;
            } else {
              return -1;
            }
          } else {
            if (a.startDate < b.startDate) {
              return 1;
            } else {
              return -1;
            }
          }
        });
      }
    } else if (column === 'END') {
      if (this.sortColumn === column) {
        this.sortDirectionDesc = !this.sortDirectionDesc;
        this.searchedDomains.sort((a: SimTacTrial, b: SimTacTrial) => {
          if (this.sortDirectionDesc) {
            if (a.endDate > b.endDate) {
              return 1;
            } else {
              return -1;
            }
          } else {
            if (a.endDate < b.endDate) {
              return 1;
            } else {
              return -1;
            }
          }
        });
      } else {
        this.sortColumn = column;
        this.searchedDomains.sort((a: SimTacTrial, b: SimTacTrial) => {
          if (this.sortDirectionDesc) {
            if (a.endDate > b.endDate) {
              return 1;
            } else {
              return -1;
            }
          } else {
            if (a.endDate < b.endDate) {
              return 1;
            } else {
              return -1;
            }
          }
        });
      }
    }
  }

  async getNumberOfMonthlyUsers() {
    this.numberOfUsersThisMonthObj =
      await this.firestoreService.getMonthlyNumberOfUsers();
    this.numberOfUsersThisMonth = this.numberOfUsersThisMonthObj.length;
    if (this.adminDataLoaded >= 7) {
      this.loader.dismiss();
    }
  }

  async getActiveSubscriptions() {
    this.numberOfActiveSubscriptionsObj =
      await this.firestoreService.getActiveSubscriptions();
    this.numberOfActiveSubscriptions =
      this.numberOfActiveSubscriptionsObj.length;
    const userIds = this.numberOfActiveSubscriptionsObj.map(
      (sub: SimTacSubscription) => sub.userId
    );

    this.numberOfActiveSubscriptionsUsersObj =
      await this.firestoreService.getUserList(userIds);
    if (this.adminDataLoaded >= 7) {
      this.loader.dismiss();
    }
  }

  async getCloudSaves() {
    this.numberOfCloudSaves = await this.firestoreService.getAllCloudSaves();
    this.numberOfCloudSaves.forEach((cloudSave: SimTacCloudSaveDetails) => {
      this.cloudSavesUsers.set(cloudSave.userId, null);
    });
    const userList = await this.firestoreService.getUserList(
      Array.from(this.cloudSavesUsers.keys())
    );
    userList.forEach((user) => {
      this.cloudSavesUsers.set(user.userId, user);
    });
  }

  async getTotalNumberOfAddOns() {
    this.numberOfTacticalAddOnsObj = await this.getTacticalAddOns();
    this.numberOfHospitalAddOnsObj = await this.getHospitalAddOns();
    this.numberOfCampusAddOnsObj = await this.getCampusAddOns();
    this.numberOfFireEmsAddOnsObj = await this.getFireEmsAddOns();
    this.numberOfTacticalAddOns = this.numberOfTacticalAddOnsObj.length;
    this.numberOfHospitalAddOns = this.numberOfHospitalAddOnsObj.length;
    this.numberOfCampusAddOns = this.numberOfCampusAddOnsObj.length;
    this.numberOfFireEmsAddOns = this.numberOfFireEmsAddOnsObj.length;

    const fireEmsUserIds = this.numberOfFireEmsAddOnsObj.map(
      (sub: SimTacPurchase) => sub.userId
    );

    const campusUserIds = this.numberOfCampusAddOnsObj.map(
      (sub: SimTacPurchase) => sub.userId
    );
    const hospitalUserIds = this.numberOfHospitalAddOnsObj.map(
      (sub: SimTacPurchase) => sub.userId
    );
    const tacticalUserIds = this.numberOfTacticalAddOnsObj.map(
      (sub: SimTacPurchase) => sub.userId
    );

    const fireEmsUserMap = new Map<string, string>();
    fireEmsUserIds.forEach((userId) => {
      fireEmsUserMap.set(userId, userId);
    });

    const campusUserMap = new Map<string, string>();
    campusUserIds.forEach((userId) => {
      campusUserMap.set(userId, userId);
    });

    const hospitalUserMap = new Map<string, string>();
    hospitalUserIds.forEach((userId) => {
      hospitalUserMap.set(userId, userId);
    });

    const tacticallUserMap = new Map<string, string>();
    tacticalUserIds.forEach((userId) => {
      tacticallUserMap.set(userId, userId);
    });

    this.numberOfFireEmsAddOnsUsers = await this.firestoreService.getUserList(
      Array.from(fireEmsUserMap.keys())
    );

    this.numberOfCampusAddOnsUsers = await this.firestoreService.getUserList(
      Array.from(campusUserMap.keys())
    );

    this.numberOfHospitalAddOnsUsers = await this.firestoreService.getUserList(
      Array.from(hospitalUserMap.keys())
    );

    this.numberOfTacticalAddOnsUsers = await this.firestoreService.getUserList(
      Array.from(tacticallUserMap.keys())
    );

    this.numberOfAddOnsUsers = [
      ...this.numberOfHospitalAddOnsUsers,
      ...this.numberOfTacticalAddOnsUsers,
      ...this.numberOfCampusAddOnsUsers,
      ...this.numberOfFireEmsAddOnsUsers,
    ];

    this.numberOfAddOnsObj = [
      ...this.numberOfTacticalAddOnsObj,
      ...this.numberOfHospitalAddOnsObj,
      ...this.numberOfCampusAddOnsObj,
      ...this.numberOfFireEmsAddOnsObj,
    ];

    this.numberOfAddOns =
      this.numberOfTacticalAddOns + this.numberOfHospitalAddOns + this.numberOfCampusAddOns + this.numberOfFireEmsAddOns;
    if (this.adminDataLoaded >= 7) {
      this.loader.dismiss();
    }
  }

  async getTacticalAddOns() {
    return await this.firestoreService.getTacticalAddOns();
  }

  async getHospitalAddOns() {
    return await this.firestoreService.getHospitalAddOns();
  }

  async getCampusAddOns() {
    return await this.firestoreService.getCampusAddOns();
  }

  async getFireEmsAddOns() {
    return await this.firestoreService.getFireEmsAddOns();
  }

  async getTotalNumberOfActiveTrials() {
    this.numberOfActiveTrialsObj =
      await this.firestoreService.getActiveTrials();
    const userIds = [];
    const domains = [];
    await this.numberOfActiveTrialsObj.forEach((trial: SimTacTrial) => {
      if (trial.domain) {
        domains.push(trial.domain);
      } else {
        userIds.push(trial.userId);
      }
    });

    if (userIds && userIds.length > 0) {
      this.numberOfActiveTrialsUsers = await this.firestoreService.getUserList(
        userIds
      );
    }
    if (domains && domains.length > 0) {
      this.numberOfActiveTrialsDomains =
        await this.firestoreService.getTrialsByDomains(domains);
    }

    this.numberOfActiveTrials = this.numberOfActiveTrialsObj.length;
    if (this.adminDataLoaded >= 7) {
      this.loader.dismiss();
    }
  }

  async getNumberOfLiveSessionsLast30Days() {
    this.numberOfLiveSessionsThisMonth =
      await this.firestoreService.getMonthlyNumberOfLiveSessions();
    if (this.adminDataLoaded >= 7) {
      this.loader.dismiss();
    }
  }

  async sendPasswordResetToSimTacUser(email: string) {
    const alert = await this.alertController.create({
      header: 'Password Reset',
      message: 'Send Password Reset Email to ' + email + '?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Yes',
          handler: async () => {
            this.loader = await this.loadingController.create({
              message: 'Sending Password Reset Email...',
            });
            this.loader.present();
            this.authService.sendPasswordResetEmail(email).then(async () => {
              this.loader.dismiss();
              const newAlert = await this.alertController.create({
                header: 'Success',
                message: 'Password Reset Email sent!',
                buttons: ['Ok'],
              });
              newAlert.present();
            });
          },
        },
      ],
    });
    await alert.present();
  }

  async grantSimTacTrialToUserOrDomain(trialIsForUser: boolean) {
    const alert = await this.alertController.create({
      header: 'Update Trial',
      message:
        'Update Trial for ' +
        (trialIsForUser
          ? this.displayedUser.email
          : this.domainTrialEmailDomain) +
        '?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Yes',
          handler: async () => {
            this.loader = await this.loadingController.create({
              message: 'Updating trial...',
            });
            this.loader.present();
            let newTrial;
            if (trialIsForUser) {
              newTrial = new SimTacTrial(
                this.displayedUser.userId,
                'user',
                Timestamp.fromDate(new Date(this.displayedUserTrialsStartDate)),
                Timestamp.fromDate(new Date(this.displayedUserTrialsEndDate)),
                '',
                this.displayedUserTrialsPremium,
                this.displayedUserTrialsTactical,
                this.displayedUserTrialsHospital,
                this.displayedUserTrialsCampus,
                this.displayedUserTrialsFireEms
              );
              await this.firestoreService.createNewTrial(newTrial);
            } else {
              newTrial = new SimTacTrial(
                '',
                'domain',
                Timestamp.fromDate(new Date(this.domainTrialStartDate)),
                Timestamp.fromDate(new Date(this.domainTrialEndDate)),
                this.domainTrialEmailDomain,
                this.domainTrialPremium,
                this.domainTrialTactical,
                this.domainTrialHospital,
                this.domainTrialCampus,
                this.domainTrialFireEms
              );
              await this.firestoreService.createNewTrial(newTrial);
              this.clearDomainFields();
            }
            this.closeUserDetails();
            this.loader.dismiss();
            this.showAlert('Success', 'Trial updated successfully');
          },
        },
      ],
    });
    await alert.present();
  }

  async alterSimTacTrialForUserOrDomain(trialIsForUser: boolean) {
    const alert = await this.alertController.create({
      header: 'Update Trial',
      message:
        'Update Trial for ' +
        (trialIsForUser
          ? this.displayedUser.email
          : this.domainTrialEmailDomain) +
        '?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Yes',
          handler: async () => {
            this.loader = await this.loadingController.create({
              message: 'Updating trial...',
            });
            this.loader.present();

            if (!trialIsForUser) {
              const newDomainTrial = this.displayedDomain;
              newDomainTrial.domain = this.domainTrialEmailDomain;
              newDomainTrial.premiumTrial = this.domainTrialPremium;
              newDomainTrial.tacticalTrial = this.domainTrialTactical;
              newDomainTrial.hospitalTrial = this.domainTrialHospital;
              newDomainTrial.campusTrial = this.domainTrialCampus;
              newDomainTrial.fireEmsTrial = this.domainTrialFireEms;
              newDomainTrial.startDate = Timestamp.fromDate(
                new Date(this.domainTrialStartDate)
              );
              newDomainTrial.endDate = Timestamp.fromDate(
                new Date(this.domainTrialEndDate)
              );
              await this.firestoreService.updateTrialForDomain(
                newDomainTrial,
                newDomainTrial.domain
              );
              this.clearDomainFields();
              this.displayDomainData = false;
            } else {
              const newUserTrial = this.displayedUserTrials;
              newUserTrial.domain = this.displayedUserTrialsDomain;
              newUserTrial.premiumTrial = this.displayedUserTrialsPremium;
              newUserTrial.tacticalTrial = this.displayedUserTrialsTactical;
              newUserTrial.hospitalTrial = this.displayedUserTrialsHospital;
              newUserTrial.campusTrial = this.displayedUserTrialsCampus;
              newUserTrial.fireEmsTrial = this.displayedUserTrialsFireEms;
              newUserTrial.startDate = Timestamp.fromDate(
                new Date(this.displayedUserTrialsStartDate)
              );
              newUserTrial.endDate = Timestamp.fromDate(
                new Date(this.displayedUserTrialsEndDate)
              );
              await this.firestoreService.updateTrialForUser(
                newUserTrial,
                this.displayedUser.userId
              );
            }
            this.closeUserDetails();
            this.loader.dismiss();
            this.showAlert('Success', 'Trial updated successfully');
          },
        },
      ],
    });
    await alert.present();
  }

  async removeTrialFromSimTacUserOrDomain(trialIsForUser: boolean) {
    const alert = await this.alertController.create({
      header: 'Remove Trial',
      message:
        'Remove Trial from ' +
        (trialIsForUser
          ? this.displayedUser.email
          : this.domainTrialEmailDomain) +
        '?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Yes',
          handler: async () => {
            this.loader = await this.loadingController.create({
              message: 'Removing trial...',
            });
            this.loader.present();
            const newTrial = trialIsForUser
              ? this.displayedUserTrials
              : this.displayedDomain;
            newTrial.endDate = Timestamp.now();

            if (!trialIsForUser) {
              await this.firestoreService.updateTrialForDomain(
                newTrial,
                newTrial.domain
              );
              this.clearDomainFields();
              this.displayDomainData = false;
            } else {
              await this.firestoreService.updateTrialForUser(
                newTrial,
                this.displayedUser.userId
              );
            }
            this.closeUserDetails();
            this.loader.dismiss();
            this.showAlert('Success', 'Trial removed successfully');
          },
        },
      ],
    });
    await alert.present();
  }

  async grantSimTacSubscriptionToUser() {
    const alert = await this.alertController.create({
      header: 'Grant Premium To User',
      message:
        'Grant Premium Subscription to ' + this.displayedUser.email + '?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Yes',
          handler: async () => {
            this.loader = await this.loadingController.create({
              message: 'Creating new subscription...',
            });
            this.loader.present();
            const newSubscription = new SimTacSubscription(
              this.displayedUserSubscriptionTier,
              this.displayedUser.userId,
              this.displayedUserSubscriptionAutoRenews === 'true',
              Timestamp.fromDate(
                new Date(this.displayedUserSubscriptionEndDate)
              ),
              this.displayedUserSubscriptionFrequencys,
              true,
              Timestamp.fromDate(
                new Date(this.displayedUserSubscriptionStartDate)
              ),
              'Premium'
            );

            await this.firestoreService.createNewSubscriptionForUser(
              newSubscription
            );
            this.closeUserDetails();
            this.loader.dismiss();
            this.showAlert('Success', 'Subscription created successfully');
          },
        },
      ],
    });
    await alert.present();
  }

  async alterSimTacSubscriptionToUser() {
    const alert = await this.alertController.create({
      header: 'Update Details',
      message: 'Update Subscription Details?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Yes',
          handler: async () => {
            this.loader = await this.loadingController.create({
              message: 'Updating subscription...',
            });
            this.loader.present();
            const newSubscription = new SimTacSubscription(
              this.displayedUserSubscriptionTier,
              this.displayedUser.userId,
              this.displayedUserSubscriptionAutoRenews === 'true',
              Timestamp.fromDate(
                new Date(this.displayedUserSubscriptionEndDate)
              ),
              this.displayedUserSubscriptionFrequencys,
              true,
              Timestamp.fromDate(
                new Date(this.displayedUserSubscriptionStartDate)
              ),
              'Premium'
            );

            await this.firestoreService.updateSubscriptionForUser(
              newSubscription,
              this.displayedUser.userId
            );
            this.closeUserDetails();
            this.loader.dismiss();
            this.showAlert('Success', 'Subscription updated successfully');
          },
        },
      ],
    });
    await alert.present();
  }

  async removeSimTacSubscriptionToUser() {
    const alert = await this.alertController.create({
      header: 'Remove Premium',
      message: 'Remove Premium from ' + this.displayedUser.email + '?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Yes',
          handler: async () => {
            this.loader = await this.loadingController.create({
              message: 'Removing subscription...',
            });
            this.loader.present();
            const newSubscription = this.displayedUserSubscriptions;
            newSubscription.endDate = Timestamp.now();

            await this.firestoreService.updateSubscriptionForUser(
              newSubscription,
              this.displayedUser.userId
            );
            this.closeUserDetails();
            this.loader.dismiss();
            this.showAlert('Success', 'Subscription removed successfully');
          },
        },
      ],
    });
    await alert.present();
  }

  async updateSimTacAddOnsForUser() {
    const alert = await this.alertController.create({
      header: 'Update Add-Ons',
      message: 'Update Add-Ons for ' + this.displayedUser.email + '?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Yes',
          handler: async () => {
            this.loader = await this.loadingController.create({
              message: 'Updating Add-Ons...',
            });
            this.loader.present();
            let hasTactical = null;
            let hasHospital = null;
            let hasCampus = null;
            let hasFireEms = null;
            this.displayUserSubscriptions.forEach((addOn) => {
              if (addOn.addOnId === constants.TACTICAL_ADDON_ID) {
                hasTactical = addOn;
              }
              if (addOn.addOnId === constants.HOSPITAL_ADDON_ID) {
                hasHospital = addOn;
              }
              if (addOn.addOnId === constants.CAMPUS_ADDON_ID) {
                hasCampus = addOn;
              }
              if (addOn.addOnId === constants.FIRE_EMS_ADDON_ID) {
                hasFireEms = addOn;
              }
            });

            //Admin has checked the add-on box and user has add-on -- check if is active --> update if not
            //Admin has checked the add-on box and the user does not have add-on --> create add-on
            //Admin has not checked the add-on box and user has the add-on --> update isActive to false
            //Admin has not checked the add-on box and the user does not have add-on --> do nothing

            if (this.displayedUserAddonsHasTactical && hasTactical) {
              if (!hasTactical.isActive) {
                hasTactical.isActive = true;
                await this.firestoreService.updateAddOnForUser(
                  hasTactical,
                  this.displayedUser.userId
                );
              }
            }
            if (this.displayedUserAddonsHasTactical && !hasTactical) {
              const tacticalAddOn = new SimTacPurchase(
                this.displayedUser.userId,
                constants.TACTICAL_ADDON_ID,
                true,
                'ADMIN ADDED'
              );
              await this.firestoreService.createNewAddOnForUser(tacticalAddOn);
            }
            if (!this.displayedUserAddonsHasTactical && hasTactical) {
              if (hasTactical.isActive) {
                hasTactical.isActive = false;
                await this.firestoreService.updateAddOnForUser(
                  hasTactical,
                  this.displayedUser.userId
                );
              }
            }

            if (this.displayedUserAddonsHasHospital && hasHospital) {
              if (!hasHospital.isActive) {
                hasHospital.isActive = true;
                await this.firestoreService.updateAddOnForUser(
                  hasHospital,
                  this.displayedUser.userId
                );
              }
            }
            if (this.displayedUserAddonsHasHospital && !hasHospital) {
              const hospitalAddOn = new SimTacPurchase(
                this.displayedUser.userId,
                constants.HOSPITAL_ADDON_ID,
                true,
                'ADMIN ADDED'
              );
              await this.firestoreService.createNewAddOnForUser(hospitalAddOn);
            }
            if (!this.displayedUserAddonsHasHospital && hasHospital) {
              if (hasHospital.isActive) {
                hasHospital.isActive = false;
                await this.firestoreService.updateAddOnForUser(
                  hasHospital,
                  this.displayedUser.userId
                );
              }
            }

            if (this.displayedUserAddonsHasCampus && hasCampus) {
              if (!hasCampus.isActive) {
                hasCampus.isActive = true;
                await this.firestoreService.updateAddOnForUser(
                  hasCampus,
                  this.displayedUser.userId
                );
              }
            }
            if (this.displayedUserAddonsHasCampus && !hasCampus) {
              const hospitalAddOn = new SimTacPurchase(
                this.displayedUser.userId,
                constants.CAMPUS_ADDON_ID,
                true,
                'ADMIN ADDED'
              );
              await this.firestoreService.createNewAddOnForUser(hospitalAddOn);
            }
            if (!this.displayedUserAddonsHasCampus && hasCampus) {
              if (hasCampus.isActive) {
                hasCampus.isActive = false;
                await this.firestoreService.updateAddOnForUser(
                  hasCampus,
                  this.displayedUser.userId
                );
              }
            }

            if (this.displayedUserAddonsHasFireEms && hasFireEms) {
              if (!hasFireEms.isActive) {
                hasFireEms.isActive = true;
                await this.firestoreService.updateAddOnForUser(
                  hasFireEms,
                  this.displayedUser.userId
                );
              }
            }
            if (this.displayedUserAddonsHasFireEms && !hasFireEms) {
              const fireEmsAddOn = new SimTacPurchase(
                this.displayedUser.userId,
                constants.FIRE_EMS_ADDON_ID,
                true,
                'ADMIN ADDED'
              );
              await this.firestoreService.createNewAddOnForUser(fireEmsAddOn);
            }
            if (!this.displayedUserAddonsHasFireEms && hasFireEms) {
              if (hasFireEms.isActive) {
                hasFireEms.isActive = false;
                await this.firestoreService.updateAddOnForUser(
                  hasFireEms,
                  this.displayedUser.userId
                );
              }
            }
            this.closeUserDetails();
            this.loader.dismiss();
            this.showAlert('Success', 'Add-Ons Updated successfully');
          },
        },
      ],
    });
    await alert.present();
  }

  async disableLiveSessions() {
    this.showAlert('Are you sure?', 'Disable live sessions?');
  }

  async forceSimTacVersionUpdate() {
    console.log(this.currentSimTacVersion);
    console.log(this.currentSimTacVersionRestriction);
    console.log(this.simTacVersionEnforcement);
    this.showAlert(
      'Enforce Version',
      'Enforce from ' +
        this.currentSimTacVersionRestriction +
        ' to ' +
        this.simTacVersionEnforcement +
        '?'
    );
  }

  async searchForUser($event) {
    if ($event && $event.detail.value) {
      this.searchIsLoading = true;
      this.searchedUsers = await this.firestoreService.getUsersByIdOrEmail(
        $event.detail.value
      );
      this.searchIsLoading = false;
    } else {
      this.searchIsLoading = false;
      this.searchedUsers = [];
    }
  }

  async searchForDomain($event) {
    if ($event && $event.detail.value) {
      this.searchIsLoading = true;
      this.searchedDomains = await this.firestoreService.searchTrialsByDomain(
        $event.detail.value
      );
      this.searchIsLoading = false;
    } else {
      this.searchIsLoading = false;
      this.searchedDomains = [];
    }
  }

  async displayUserMaps(user: SimTacUser) {
    this.displayedUserCloudSaves =
      await this.firestoreService.getCloudSavesList(user.userId);
    this.displayedUser = user;
    this.displayCloudSaveData = true;
  }

  async openUserDetails(user: SimTacUser) {
    this.displayedUser = user;
    this.displayedUserSubscriptionsLoading = true;
    this.displayedUserAddonsLoading = true;
    this.displayedUserTrialsLoading = true;
    this.newEmail = this.displayedUser.email;
    this.firestoreService
      .getPurchasesByUserId(user.userId)
      .then((purchases) => {
        this.displayUserSubscriptions = purchases;
        purchases.forEach((purchased) => {
          if (
            purchased.addOnId === constants.TACTICAL_ADDON_ID &&
            purchased.isActive
          ) {
            this.displayedUserAddons.push('Tactical');
            this.displayedUserAddonsHasTactical = true;
            this.displayedUserAddonsHasTacticalInitialValue = true;
          }
          if (
            purchased.addOnId === constants.HOSPITAL_ADDON_ID &&
            purchased.isActive
          ) {
            this.displayedUserAddons.push('Hospital');
            this.displayedUserAddonsHasHospital = true;
            this.displayedUserAddonsHasHospitalInitialValue = true;
          }
          if (
            purchased.addOnId === constants.CAMPUS_ADDON_ID &&
            purchased.isActive
          ) {
            this.displayedUserAddons.push('Campus');
            this.displayedUserAddonsHasCampus = true;
            this.displayedUserAddonsHasCampusInitialValue = true;
          }
          if (
            purchased.addOnId === constants.FIRE_EMS_ADDON_ID &&
            purchased.isActive
          ) {
            this.displayedUserAddons.push('Fire/EMS');
            this.displayedUserAddonsHasFireEms = true;
            this.displayedUserAddonsHasFireEmsInitialValue = true;
          }
          
        });
        this.displayedUserAddonsLoading = false;
      });
    this.firestoreService
      .getSubscriptionsByUserId(user.userId)
      .then((subscriptions) => {
        if (subscriptions.length > 0) {
          this.displayedUserSubscriptions = subscriptions[0];
          this.displayedUserSubscriptions.tier = this.getSubscriptionTier(
            this.displayedUserSubscriptions.addOnId
          );
          this.displayedUserSubscriptionTier =
            this.displayedUserSubscriptions.addOnId;
          this.displayedUserSubscriptionAutoRenews =
            this.displayedUserSubscriptions.autoRenewal.toString();
          this.displayedUserSubscriptionFrequencys =
            this.displayedUserSubscriptions.frequency;
          this.displayedUserSubscriptionStartDate = formatDate(
            this.displayedUserSubscriptions.startDate.toDate(),
            'MM/dd/YYYY',
            'en-US'
          );
          this.displayedUserSubscriptionEndDate = formatDate(
            this.displayedUserSubscriptions.endDate.toDate(),
            'MM/dd/YYYY',
            'en-US'
          );
        }
        this.displayedUserSubscriptionsLoading = false;
      });
    this.firestoreService.getTrialByUserId(user.userId).then((trials) => {
      trials.forEach((trial) => {
        if (trial.endDate.toDate() > new Date()) {
          this.displayedUserTrials = trial;
          this.displayedUserTrialsStartDate = formatDate(
            trial.startDate.toDate(),
            'MM/dd/YYYY',
            'en-US'
          );
          this.displayedUserTrialsEndDate = formatDate(
            trial.endDate.toDate(),
            'MM/dd/YYYY',
            'en-US'
          );
          this.displayedUserTrialsDomain = trial.domain;
          this.displayedUserTrialsPremium = trial.premiumTrial;
          this.displayedUserTrialsTactical = trial.tacticalTrial;
          this.displayedUserTrialsHospital = trial.hospitalTrial;
          this.displayedUserTrialsCampus = trial.campusTrial;
          this.displayedUserTrialsFireEms = trial.fireEmsTrial
          this.displayedUserTrialsStartDateInitialValue =
            this.displayedUserTrialsStartDate;
          this.displayedUserTrialsEndDateInitialValue =
            this.displayedUserTrialsEndDate;
          this.displayedUserTrialsDomainInitialValue =
            this.displayedUserTrialsDomain;
          this.displayedUserTrialsPremiumInitialValue =
            this.displayedUserTrialsPremium;
          this.displayedUserTrialsTacticalInitialValue =
            this.displayedUserTrialsTactical;
          this.displayedUserTrialsHospitalInitialValue =
            this.displayedUserTrialsHospital;
          this.displayedUserTrialsCampusInitialValue =
            this.displayedUserTrialsCampus;
            this.displayedUserTrialsFireEmsInitialValue = 
            this.displayedUserTrialsFireEms;
          this.displayedUserTrialsLoading = false;
        }
      });
      this.displayedUserTrialsLoading = false;
    });
    this.displayUserData = true;
  }

  async openDomainDetails(domainTrial: SimTacTrial) {
    this.displayedDomain = domainTrial;
    this.domainTrialEmailDomain = domainTrial.domain;
    this.domainTrialStartDate = formatDate(
      domainTrial.startDate.toDate(),
      'MM/dd/YYYY',
      'en-US'
    );
    this.domainTrialEndDate = formatDate(
      domainTrial.endDate.toDate(),
      'MM/dd/YYYY',
      'en-US'
    );
    this.domainTrialPremium = domainTrial.premiumTrial;
    this.domainTrialTactical = domainTrial.tacticalTrial;
    this.domainTrialHospital = domainTrial.hospitalTrial;
    this.domainTrialCampus = domainTrial.campusTrial;
    this.domainTrialFireEms = domainTrial.fireEmsTrial;

    this.displayDomainData = true;
  }

  getSubscriptionTier(addOnId) {
    if (addOnId === constants.BASIC_SUBSCTRIPTION) {
      return 'Basic Subscription';
    }
    if (addOnId === constants.STANDARD_SUBSCTRIPTION) {
      return 'Standard Subscription';
    }
    if (addOnId === constants.DELUXE_SUBSCTRIPTION) {
      return 'Deluxe Subscription';
    }
    if (addOnId === constants.PREMIUM_SUBSCTRIPTION) {
      return 'Premium Subscription';
    }
    return 'Error - Unknown Subscription Tier';
  }

  async closeUserDetails() {
    this.displayUserData = false;
    this.displayCloudSaveData = false;
    this.displayedUser = null;
    this.displayUserSubscriptions = null;
    this.displayedUserAddons = [];
    this.displayedUserAddonsHasTactical = false;
    this.displayedUserAddonsHasHospital = false;
    this.displayedUserAddonsHasCampus = false;
    this.displayedUserAddonsHasTacticalInitialValue = false;
    this.displayedUserAddonsHasHospitalInitialValue = false;
    this.displayedUserAddonsHasCampusInitialValue = false;
    this.displayedUserSubscriptions = null;
    this.displayedUserTrials = null;
    this.displayedUserTrialsStartDate = '';
    this.displayedUserTrialsEndDate = '';
    this.displayedUserTrialsDomain = '';
    this.displayedUserTrialsPremium = false;
    this.displayedUserTrialsTactical = false;
    this.displayedUserTrialsHospital = false;
    this.displayedUserTrialsCampus = false;
    this.displayedUserTrialsFireEms = false
    this.displayedUserTrialsStartDateInitialValue = '';
    this.displayedUserTrialsEndDateInitialValue = '';
    this.displayedUserTrialsDomainInitialValue = '';
    this.displayedUserTrialsPremiumInitialValue = false;
    this.displayedUserTrialsTacticalInitialValue = false;
    this.displayedUserTrialsHospitalInitialValue = false;
    this.displayedUserTrialsCampusInitialValue = false;
    this.displayedUserTrialsFireEmsInitialValue = false;
    this.displayedUserTrialsLoading = false;
    this.displayedUserSubscriptionsLoading = false;
    this.displayedUserAddonsLoading = false;
    this.displayedUserTrialsLoading = false;
    this.displayedUserSubscriptionTier = '';
    this.displayedUserSubscriptionAutoRenews = '';
    this.displayedUserSubscriptionFrequencys = '';
    this.displayedUserSubscriptionStartDate = '';
    this.displayedUserSubscriptionEndDate = '';
    this.displayedUserCloudSaves = [];
    this.searchedUsers = [];
  }

  async clearDomainFields() {
    this.domainTrialEmailDomain = '';
    this.domainTrialStartDate = '';
    this.domainTrialEndDate = '';
    this.domainTrialPremium = false;
    this.domainTrialTactical = false;
    this.domainTrialHospital = false;
    this.domainTrialCampus = false;
    this.domainTrialFireEms = false;
    this.displayDomainData = false;
    this.displayedDomain = null;
  }

  async upateUserEmail() {
    if (this.displayedUser && this.newEmail) {
      this.showLoader('Updating Email...', 10000);
      await this.authService.updateUserEmail(
        this.displayedUser.userId,
        this.newEmail
      );
      this.displayedUser.email = this.newEmail;
      this.loader.dismiss();
      this.showAlert('Success', 'Email Updated Successfully!');
      this.newEmail = null;
    }
  }

  async updateUserPassword() {
    if (this.displayedUser && this.newPassword) {
      this.showLoader('Updating Password...', 3000);
      await this.authService.updateUserPassword(
        this.displayedUser.userId,
        this.newPassword
      );
      this.loader.dismiss();
      this.showAlert('Success', 'Password Updated Successfully!');
      this.newPassword = null;
    }
  }

  async createNewSimTacUser() {
    if (this.newUserEmail && this.newUserPassword) {
      const alert = await this.alertController.create({
        header: 'Create New User?',
        message:
          'Email: ' + this.newUserEmail + '\nPassword: ' + this.newUserPassword,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
          },
          {
            text: 'Yes',
            handler: async () => {
              this.showLoader('Creating New User..', 10000);
              let response = await this.authService.createNewSimTacUser(
                this.newUserEmail,
                this.newUserPassword
              );
              let users = await this.firestoreService.getUsersByIdOrEmail(this.newUserEmail);
              users.forEach(async (user) => {
                await this.firestoreService.updateUserAccess(user.userId);
              })
              this.loader.dismiss();
              this.showAlert('Success', 'User Created Successfully!');
              this.newUserPassword = null;
              this.newUserEmail = null;
            },
          },
        ],
      });
      await alert.present();
    }
  }

  async openCloudSave(cloudSave: SimTacCloudSaveDetails) {
    this.eventService.currentCloudSaveId.next(cloudSave.cloudSaveId);
    this.eventService.mapType.next(cloudSave.mapImage.fileName);
    this.firestoreService.listenForCloudSaveEvents(cloudSave.userId);
    this.router.navigateByUrl('/tabs');
  }

  async deleteSimTacUser() {
    if (this.displayedUser) {
      const alert = await this.alertController.create({
        header: 'Delete User',
        message:
          'Are you sure you want to DELETE user: ' +
          this.displayedUser.email +
          '?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
          },
          {
            text: 'Yes',
            handler: async () => {
              this.loader = await this.loadingController.create({
                message: 'Deleting User...',
              });
              this.loader.present();
              await this.authService.deleteNewSimTacUser(
                this.displayedUser.userId
              );
              this.loader.dismiss();
              this.showAlert('Success', 'User Deleted Successfully!');
              this.newPassword = null;
              this.closeUserDetails();
              this.searchForUser(null);
            },
          },
        ],
      });
      await alert.present();
    }
  }

  async addRevokeAccess() {
    if (this.displayedUser) {
      const alert = await this.alertController.create({
        header: this.displayedUser.hasAccess ? 'Revoke Access' : 'Add Access',
        message:
          'Are you sure you want to edit user access?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
          },
          {
            text: 'Yes',
            handler: async () => {
              this.loader = await this.loadingController.create({
                message: this.displayedUser.hasAccess ? 'Revoking Access...' : 'Adding Access...',
              });
              this.loader.present();
              await this.firestoreService.updateUserAccess(
                this.displayedUser.userId, this.displayedUser.hasAccess
              );
              this.loader.dismiss();
              this.showAlert('Success', 'User Access Modified!');
              this.closeUserDetails();
              this.searchForUser(null);
            },
          },
        ],
      });
      await alert.present();
    }
  }
}
