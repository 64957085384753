/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  LoadingController,
  AlertController,
  ModalController,
} from '@ionic/angular';
import { ForgotPasswordModal } from '../modals/forgot-password-modal/forgot-password-modal';
import { AuthService } from '../services/auth.service';
import { EventService } from '../services/event-service';
import { FirestoreService } from '../services/firestore.service';
import { emailVerified } from '@angular/fire/auth-guard';
import { Timestamp } from '@angular/fire/firestore';
import { SimTacUser } from '../models/firestore-models';

@Component({
  selector: 'app-no-access',
  templateUrl: 'no-access.html',
  styleUrls: ['no-access.scss'],
})
export class NoAccessPage implements OnInit {
  credentials: FormGroup;

  constructor(
    private eventService: EventService,
    private fb: FormBuilder,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private authService: AuthService,
    private router: Router,
    private modalController: ModalController,
    private firestoreService: FirestoreService
  ) {}

  signup() {
    this.router.navigateByUrl('map-selection');
  }

  get email() {
    return this.credentials.get('email');
  }

  get password() {
    return this.credentials.get('password');
  }

  ngOnInit() {
    this.credentials = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  async logout() {
    const loading = await this.loadingController.create({
      message: 'Logging out...',
    });
    await loading.present();
    this.authService.logout();
    await loading.dismiss();
    this.router.navigateByUrl('home', { replaceUrl: true });
  }

  async showAlert(header, message) {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: ['OK'],
    });
    await alert.present();
  }
}
