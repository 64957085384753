import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SimTacAddOn, SimTacOrderEmail } from 'src/app/models/firestore-models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'stripe-modal',
  templateUrl: 'stripe-modal.html',
  styleUrls: ['stripe-modal.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class StripeModal {
  @Input() addOnToPurchase: SimTacAddOn;
  @Input() frequency: string;
  @Input() emailModel: SimTacOrderEmail;

  constructor(private modalController: ModalController) {
    console.log(this.emailModel);
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
