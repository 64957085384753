import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  FileCoordinates,
  LocalFile,
  SimTacMap,
  SimTacMapDetails,
} from '../models/local-files';
import {
  Filesystem,
  Directory as CapacitorDir,
  Encoding,
} from '@capacitor/filesystem';
import { EventService } from './event-service';
import { FirestoreService } from './firestore.service';
import { Timestamp } from '@angular/fire/firestore';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(
    private eventService: EventService,
    private fireStoreService: FirestoreService,
    private authService: AuthService
  ) {
    Filesystem.mkdir({
      path: 'maps/',
      directory: CapacitorDir.Documents,
    })
      .then(() => {})
      .catch((err) => {
        if (err.message.includes('already exist')) {
        } else {
        }
      });

    this.eventService.performMapSave.subscribe((save) => {
      if (save) {
        this.saveMap();
      }
    });
  }

  public async getMap(mapName: string) {
    await Filesystem.readFile({
      path: 'maps/' + mapName,
      directory: CapacitorDir.Documents,
    }).then((readResult) => {
      const map: SimTacMap = JSON.parse(
        atob(readResult.data),
        this.reviver
      ) as SimTacMap;
      this.eventService.customMapImage.next(map.mapImage);
      this.eventService.filesOnScreen = map.fileList;
      this.eventService.filesOnScreenSubject.next(map.fileList);
      this.eventService.mapType.next('custom');
      this.eventService.currentMapDetails.next(map.mapDetails);
      this.eventService.uneditedMapDetails.next(map.mapDetails);
      this.eventService.currentMap.next(map);
      this.eventService.mapLocked.next(map.locked);
      this.eventService.mapLockPassword.next(map.mapPassword);
    });
  }

  replacer(key, value) {
    if (value instanceof Map) {
      return {
        dataType: 'Map',
        value: Array.from(value.entries()), // or with spread: value: [...value]
      };
    } else {
      return value;
    }
  }

  reviver(key, value) {
    if (typeof value === 'object' && value !== null) {
      if (value.dataType === 'Map') {
        return new Map(value.value);
      }
    }
    return value;
  }

  public async saveMap() {
    if (
      !this.eventService.currentMapDetails.value ||
      this.eventService.currentMapDetails.value.mapName === ''
    ) {
      return;
    }

    const mapFiles = this.eventService.filesOnScreen;
    let mapImg = '';
    switch (this.eventService.mapType.value) {
      case 'school':
        mapImg = 'school.jpg';
        break;
      case 'hospital':
        mapImg = 'hospital.jpg';
        break;
      case 'theater':
        mapImg = 'theater.jpg';
        break;
      case 'city':
        mapImg = 'simtaccity.jpg';
        break;
      case 'fire/ems':
        mapImg = 'FireEms.png';
        break;
      case 'campus':
        mapImg = 'Campus.png';
        break;
      case 'school.jpg':
        mapImg = '../../assets/maps/school.jpg';
        break;
      case 'hospital.jpg':
        mapImg = '../../assets/maps/hospital.jpg';
        break;
      case 'theater.jpg':
        mapImg = '../../assets/maps/theater.jpg';
        break;
      case 'simtaccity.jpg':
        mapImg = '../../assets/maps/simtaccity.jpg';
        break;
      case 'FireEms.png':
        mapImg = '../../assets/maps/FireEms.png';
        break;
      case 'Campus.png':
        mapImg = '../../assets/maps/Campus.png';
        break;
      default:
        break;
    }
    const mapFileCoordinates = new FileCoordinates();
    mapFileCoordinates.width =
      this.eventService.hostMapImageRenderedSize.value.width;
    mapFileCoordinates.height =
      this.eventService.hostMapImageRenderedSize.value.height;
    const thing: LocalFile = new LocalFile(
      'mapImage',
      mapImg,
      '/assets/maps',
      mapFileCoordinates
    );
    const mapImage =
      this.eventService.mapType.value === 'custom'
        ? this.eventService.customMapImage.value
        : thing;
    const savedDetails = this.eventService.currentMapDetails.value;
    const uneditedDetails = this.eventService.uneditedMapDetails.value;

    if (
      this.eventService.mapType.value === 'custom' &&
      !mapImage.data.includes('https://')
    ) {
      const data = mapImage.data.split(',')[1];
      const uploadTask = this.fireStoreService.uploadImageToFirebase(
        data,
        mapImage.name
      );
      let imgUrl = '';
      await uploadTask.then(async (uploadResult) => {
        imgUrl = await uploadResult.ref.getDownloadURL();
      });
      mapImage.data = imgUrl;
    }

    const isMapLocked: boolean = this.eventService.mapLocked.value;
    const mapPassword: string = this.eventService.mapLockPassword.value;
    const map: SimTacMap = new SimTacMap(
      mapFiles,
      mapImage,
      savedDetails,
      isMapLocked,
      mapPassword,
      '',
      Timestamp.now(),
      this.authService.userId.value
    );

    if (
      uneditedDetails.mapName !== '' &&
      uneditedDetails.mapName !== savedDetails.mapName
    ) {
      await this.deleteMap(uneditedDetails.mapName);
    }

    if (
      this.eventService.currentSession.value &&
      this.eventService.currentSession.value !== ''
    ) {
      this.fireStoreService.saveMapData(map);
      return;
    }

    // await Filesystem.writeFile({
    //   path: 'maps/' + savedDetails.mapName,
    //   data: btoa(JSON.stringify(map, this.replacer)),
    //   directory: CapacitorDir.Documents,
    // });

    if (this.eventService.currentMap.value) {
      map.cloudSaveId = this.eventService.currentMap.value.cloudSaveId;
    }

    const newMap = await this.fireStoreService.performCloudSave(
      map,
      this.authService.userId.value
    );

    this.eventService.currentMap.next(newMap);

    const mapList = this.eventService.savedMapList.value;
    mapList.set(savedDetails.mapName, map);

    this.eventService.savedMapList.next(mapList);
    this.eventService.filesOnScreenSubject.next(this.eventService.filesOnScreen);
  }

  public async deleteMap(mapName: string) {
    if (
      this.eventService.currentMap.value &&
      this.eventService.currentMap.value.cloudSaveId
    ) {
      return await this.fireStoreService.deleteCloudSave(
        this.eventService.currentMap.value.cloudSaveId
      );
    }

    await Filesystem.deleteFile({
      path: 'maps/' + mapName,
      directory: CapacitorDir.Documents,
    }).then((readResult) => {
      const mapList = this.eventService.savedMapList.value;
      if (mapList.has(mapName)) {
        mapList.delete(mapName);
      }
      this.eventService.savedMapList.next(mapList);
    });
  }

  public async getAllMaps() {
    this.getCloudSaves();
    // const maps: Map<string, SimTacMap> = new Map();
    // await Filesystem.readdir({
    //   path: 'maps/',
    //   directory: CapacitorDir.Documents,
    // }).then(async (readResult) => {
    //   if (readResult) {
    //     await readResult.files.forEach(async (file) => {
    //       await Filesystem.readFile({
    //         path: 'maps/' + file.name,
    //         directory: CapacitorDir.Documents,
    //       }).then((readResultTwo) => {
    //         const map: SimTacMap = JSON.parse(
    //           atob(readResultTwo.data)
    //         ) as SimTacMap;
    //         maps.set(map.mapDetails.mapName, map);
    //         this.eventService.savedMapList.next(maps);
    //       });
    //     });
    //     this.eventService.savedMapList.next(maps);
    //   }
    // });
  }

  public async getCloudSaves() {
    const saves = await this.fireStoreService.getCloudSavesList(
      this.authService.userId.value
    );
    this.eventService.cloudSavesList.next(saves);
  }
}
