/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  query,
  where,
  getDocs,
  Timestamp,
  addDoc,
  updateDoc,
  deleteDoc,
} from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/compat/database';

import {
  SimTacAddOn,
  SimTacPurchase,
  SimTacResource,
  SimTacSession,
  SimTacSubscription,
  SimTacTrial,
  SimTacUser,
} from '../models/firestore-models';
import { get, off, onValue, ref } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import {
  LocalFile,
  SimTacCloudSaveDetails,
  SimTacMap,
  SimTacMapFlattened,
} from '../models/local-files';

import { EventService } from './event-service';
import { constants } from '../constants/constants';

@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  sessionId = '';
  cloudSaveId = '';
  currentSessionReference: any;

  constructor(
    private firestore: Firestore,
    private db: AngularFireDatabase,
    private eventService: EventService,
    private storage: AngularFireStorage
  ) {
    this.eventService.currentSession.subscribe((sessionId) => {
      if (sessionId && sessionId !== '') {
        this.sessionId = sessionId;
      }
    });

    this.eventService.currentCloudSaveId.subscribe((cloudSaveId) => {
      if (cloudSaveId && cloudSaveId !== '') {
        this.cloudSaveId = cloudSaveId;
      }
    });
  }

  async saveMapData(mapData: SimTacMap) {
    const itemRef = this.db.object('sessions/' + this.sessionId);
    itemRef.set(mapData.toDTO());
  }

  async performCloudSave(mapData: SimTacMap, userId: string) {
    // Check for cloud save in firestore as well
    if (mapData.cloudSaveId === 'session') {
      return;
    }
    mapData.updatedAt = Timestamp.now();
    if (!mapData.cloudSaveId) {
      mapData = await this.createCloudSaveId(mapData, userId);
    }
    const itemRef = this.db.object('cloud-saves/' + mapData.cloudSaveId);
    let dto = mapData.toDTO() 
    itemRef.set(dto);
    return mapData;
  }

  async createCloudSaveId(mapData: SimTacMap, userId: string) {
    const details: SimTacCloudSaveDetails = new SimTacCloudSaveDetails(
      mapData.mapDetails.mapName,
      mapData.mapDetails.mapAuthor,
      mapData.mapDetails.mapDescription,
      mapData.mapDetails.mapAuthorUrl,
      userId,
      '',
      Timestamp.now(),
      mapData.mapImage
    );

    const collectionRef = collection(this.firestore, 'cloud-saves');
    await addDoc(collectionRef, JSON.parse(JSON.stringify(details))).then(
      async (d) => {
        details.cloudSaveId = d.id;
        await updateDoc(d, JSON.parse(JSON.stringify(details)));
      }
    );

    mapData.cloudSaveId = details.cloudSaveId;

    return mapData;
  }

  async deleteCloudSave(cloudSaveId) {
    const q = query(
      collection(this.firestore, 'cloud-saves'),
      where('cloudSaveId', '==', cloudSaveId)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      deleteDoc(doc.ref);
    });

    const itemRef = this.db.object('cloud-saves/' + cloudSaveId);
    await itemRef.remove();
    return true;
  }

  async getCloudSavesList(userId: string) {
    const q = query(
      collection(this.firestore, 'cloud-saves'),
      where('userId', '==', userId)
    );
    const querySnapshot = await getDocs(q);
    const cloudSaves: SimTacCloudSaveDetails[] = [];
    querySnapshot.forEach((doc) => {
      cloudSaves.push(doc.data() as SimTacCloudSaveDetails);
    });

    return cloudSaves;
  }

  async listenForCloudSaveEvents(userId: string) {
    const db = this.db.database;
    const starCountRef = ref(db, 'cloud-saves/' + this.cloudSaveId);
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const ourBoi = data as SimTacMapFlattened;
        const ourBoiRevived = new SimTacMap(
          new Map(JSON.parse(ourBoi.fileList)) as Map<string, LocalFile>,
          ourBoi.mapImage,
          ourBoi.mapDetails,
          ourBoi.locked,
          ourBoi.mapPassword,
          ourBoi.cloudSaveId,
          ourBoi.updatedAt,
          userId
        );
        this.eventService.filesOnScreen = ourBoiRevived.fileList;
        this.eventService.filesOnScreenSubject.next(new Map());
        this.eventService.filesOnScreenSubject.next(ourBoiRevived.fileList);
        this.eventService.currentMapDetails.next(ourBoiRevived.mapDetails);
        this.eventService.hostMapImageRenderedSize.next({
          width: ourBoiRevived.mapImage.fileCoordinates.width,
          height: ourBoiRevived.mapImage.fileCoordinates.height,
        });
        this.eventService.currentMap.next(ourBoiRevived);
      }
    });
    const snapshot2 = await get(starCountRef);
    const data2 = snapshot2.val();
    if (data2) {
      const ourBoi = data2 as SimTacMapFlattened;
      const ourBoiRevived = new SimTacMap(
        new Map(JSON.parse(ourBoi.fileList)) as Map<string, LocalFile>,
        ourBoi.mapImage,
        ourBoi.mapDetails,
        ourBoi.locked,
        ourBoi.mapPassword,
        ourBoi.cloudSaveId,
        ourBoi.updatedAt,
        userId
      );
      this.eventService.filesOnScreen = ourBoiRevived.fileList;
      this.eventService.filesOnScreenSubject.next(new Map());
      this.eventService.filesOnScreenSubject.next(ourBoiRevived.fileList);
      this.eventService.currentMapDetails.next(ourBoiRevived.mapDetails);
      this.eventService.hostMapImageRenderedSize.next({
        width: ourBoiRevived.mapImage.fileCoordinates.width,
        height: ourBoiRevived.mapImage.fileCoordinates.height,
      });
      this.eventService.currentMap.next(ourBoiRevived);
    }
    this.currentSessionReference = starCountRef;
  }

  async getCloudSaveEvent(userId: string) {
    const db = this.db.database;
    const starCountRef = ref(db, 'cloud-saves/' + this.cloudSaveId);
    let map: SimTacMap = null;
    const data = (await get(starCountRef)).val();
      if (data) {
        const ourBoi = data as SimTacMapFlattened;
        const ourBoiRevived = new SimTacMap(
          new Map(JSON.parse(ourBoi.fileList)) as Map<string, LocalFile>,
          ourBoi.mapImage,
          ourBoi.mapDetails,
          ourBoi.locked,
          ourBoi.mapPassword,
          ourBoi.cloudSaveId,
          ourBoi.updatedAt,
          userId
        );
        this.eventService.filesOnScreen = ourBoiRevived.fileList;
        this.eventService.filesOnScreenSubject.next(new Map());
        this.eventService.filesOnScreenSubject.next(ourBoiRevived.fileList);
        this.eventService.currentMapDetails.next(ourBoiRevived.mapDetails);
        this.eventService.hostMapImageRenderedSize.next({
          width: ourBoiRevived.mapImage.fileCoordinates.width,
          height: ourBoiRevived.mapImage.fileCoordinates.height,
        });
        this.eventService.currentMap.next(ourBoiRevived);
        map = ourBoiRevived;
      }
    const snapshot2 = await get(starCountRef);
    const data2 = snapshot2.val();
    if (data2) {
      const ourBoi = data2 as SimTacMapFlattened;
      const ourBoiRevived = new SimTacMap(
        new Map(JSON.parse(ourBoi.fileList)) as Map<string, LocalFile>,
        ourBoi.mapImage,
        ourBoi.mapDetails,
        ourBoi.locked,
        ourBoi.mapPassword,
        ourBoi.cloudSaveId,
        ourBoi.updatedAt,
        userId
      );
      this.eventService.filesOnScreen = ourBoiRevived.fileList;
      this.eventService.filesOnScreenSubject.next(new Map());
      this.eventService.filesOnScreenSubject.next(ourBoiRevived.fileList);
      this.eventService.currentMapDetails.next(ourBoiRevived.mapDetails);
      this.eventService.hostMapImageRenderedSize.next({
        width: ourBoiRevived.mapImage.fileCoordinates.width,
        height: ourBoiRevived.mapImage.fileCoordinates.height,
      });
      this.eventService.currentMap.next(ourBoiRevived);
      map = ourBoiRevived;
    }
    this.currentSessionReference = starCountRef;
    return map;
  }

  async listenForEvents(userId: string) {
    const db = this.db.database;
    const starCountRef = ref(db, 'sessions/' + this.sessionId);
    console.log('Listening for events... : ' + this.sessionId);
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        console.log('Got data...');
        const ourBoi = data as SimTacMapFlattened;
        const ourBoiRevived = new SimTacMap(
          new Map(JSON.parse(ourBoi.fileList)) as Map<string, LocalFile>,
          ourBoi.mapImage,
          ourBoi.mapDetails,
          ourBoi.locked,
          ourBoi.mapPassword,
          'session',
          ourBoi.updatedAt,
          userId
        );
        this.eventService.filesOnScreen = ourBoiRevived.fileList;
        this.eventService.filesOnScreenSubject.next(new Map());
        this.eventService.filesOnScreenSubject.next(ourBoiRevived.fileList);
        this.eventService.currentMapDetails.next(ourBoiRevived.mapDetails);
        this.eventService.hostMapImageRenderedSize.next({
          width: ourBoiRevived.mapImage.fileCoordinates.width,
          height: ourBoiRevived.mapImage.fileCoordinates.height,
        });
      }
    });
    this.currentSessionReference = starCountRef;
  }

  async unsubscribeFromSessionEvents() {
    await off(this.currentSessionReference);
    console.log('Unsubscribed from events...');
  }

  uploadImageToFirebase(img, fileName) {
    const otherGuy = this.storage
      .ref(fileName)
      .putString(img, 'base64', { contentType: 'image/jpg' });
    return otherGuy;
  }

  async getUserById(userId): Promise<SimTacUser> {
    const q = query(
      collection(this.firestore, 'users'),
      where('userId', '==', userId)
    );
    const querySnapshot = await getDocs(q);
    let user = null;
    querySnapshot.forEach((doc) => {
      user = doc.data() as SimTacUser;
    });

    return new SimTacUser(
      userId,
      user.email,
      user.emailVerified,
      user.isAdmin ? user.isAdmin : false,
      Timestamp.now(),
      user.hasAccess ? user.hasAccess : false
    );
  }

  async getPurchasesByUserId(userId): Promise<SimTacPurchase[]> {
    const q = query(
      collection(this.firestore, 'purchased'),
      where('userId', '==', userId)
    );
    const querySnapshot = await getDocs(q);
    const purchases: SimTacPurchase[] = [];
    querySnapshot.forEach((doc) => {
      purchases.push(doc.data() as SimTacPurchase);
    });
    return purchases;
  }

  async getSubscriptionsByUserId(userId): Promise<SimTacSubscription[]> {
    const q = query(
      collection(this.firestore, 'premium'),
      where('userId', '==', userId),
      where('endDate', '>', new Date())
    );
    const querySnapshot = await getDocs(q);
    const subscriptions: SimTacSubscription[] = [];
    querySnapshot.forEach((doc) => {
      subscriptions.push(doc.data() as SimTacSubscription);
    });
    return subscriptions;
  }

  async getAllAddOns(): Promise<SimTacAddOn[]> {
    const q = query(collection(this.firestore, 'addons'));
    const querySnapshot = await getDocs(q);
    const addOns: SimTacAddOn[] = [];
    querySnapshot.forEach((doc) => {
      const addOn = doc.data() as SimTacAddOn;
      const frequencies = doc.data().frequency;
      addOn.frequency = new Map<string, number>();
      Object.keys(frequencies).forEach((key) => {
        addOn.frequency.set(key, frequencies[key]);
      });
      addOns.push(addOn);
    });
    return addOns;
  }

  async getTrialsByDomain(domain: string): Promise<SimTacTrial[]> {
    const q = query(
      collection(this.firestore, 'trial'),
      where('endDate', '>=', Timestamp.now()),
      where('domain', '==', domain)
    );
    const querySnapshot = await getDocs(q);
    const trials: SimTacTrial[] = [];
    querySnapshot.forEach((doc) => {
      if (doc.data().startDate <= Timestamp.now()) {
        trials.push(doc.data() as SimTacTrial);
      }
    });
    return trials;
  }

  async searchTrialsByDomain(domain: string): Promise<SimTacTrial[]> {
    const q = query(
      collection(this.firestore, 'trial'),
      where('domain', '>=', domain),
      where('domain', '<=', domain + '\uf8ff')
    );
    const querySnapshot = await getDocs(q);
    const trials: SimTacTrial[] = [];
    querySnapshot.forEach((doc) => {
      if (doc.data().endDate >= Timestamp.now()) {
        trials.push(doc.data() as SimTacTrial);
      }
    });
    return trials;
  }

  async getTrialsByDomains(domains: string[]): Promise<SimTacTrial[]> {
    const q = query(
      collection(this.firestore, 'trial'),
      where('domain', 'in', domains)
    );
    const querySnapshot = await getDocs(q);
    const trials: SimTacTrial[] = [];
    querySnapshot.forEach((doc) => {
      if (doc.data().endDate >= Timestamp.now()) {
        trials.push(doc.data() as SimTacTrial);
      }
    });
    return trials;
  }

  async getTrialByUserId(userId: string): Promise<SimTacTrial[]> {
    const q = query(
      collection(this.firestore, 'trial'),
      where('userId', '==', userId)
    );
    const querySnapshot = await getDocs(q);
    const trials: SimTacTrial[] = [];
    querySnapshot.forEach((doc) => {
      trials.push(doc.data() as SimTacTrial);
    });
    return trials;
  }

  async createSimTacUser(user: SimTacUser): Promise<SimTacUser> {
    const collectionRef = collection(this.firestore, 'users');
    user.lastLogin = Timestamp.now();
    const newUserId = await addDoc(
      collectionRef,
      JSON.parse(JSON.stringify(user))
    ).then((d) => d.id);

    return user;
  }

  async createNewSubscriptionForUser(
    subscription: SimTacSubscription
  ): Promise<string> {
    const firebaseObj = JSON.parse(JSON.stringify(subscription));
    firebaseObj.endDate = subscription.endDate;
    firebaseObj.startDate = subscription.startDate;
    const collectionRef = collection(this.firestore, 'premium');
    const docId = await addDoc(collectionRef, firebaseObj).then((d) => d.id);

    return docId;
  }

  async updateSubscriptionForUser(
    subscription: SimTacSubscription,
    userId
  ): Promise<boolean> {
    const q = query(
      collection(this.firestore, 'premium'),
      where('userId', '==', userId)
    );
    const firebaseObj = JSON.parse(JSON.stringify(subscription));
    firebaseObj.endDate = subscription.endDate;
    firebaseObj.startDate = subscription.startDate;
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      data = firebaseObj;
      updateDoc(doc.ref, data);
    });
    return true;
  }

  async updateTrialForUser(trial: SimTacTrial, userId): Promise<boolean> {
    const q = query(
      collection(this.firestore, 'trial'),
      where('userId', '==', userId)
    );
    const firebaseObj = JSON.parse(JSON.stringify(trial));
    firebaseObj.endDate = trial.endDate;
    firebaseObj.startDate = trial.startDate;
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      data = firebaseObj;
      updateDoc(doc.ref, data);
    });
    return true;
  }

  async updateTrialForDomain(trial: SimTacTrial, domain): Promise<boolean> {
    const q = query(
      collection(this.firestore, 'trial'),
      where('domain', '==', domain)
    );
    const firebaseObj = JSON.parse(JSON.stringify(trial));
    firebaseObj.endDate = trial.endDate;
    firebaseObj.startDate = trial.startDate;
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      data = firebaseObj;
      updateDoc(doc.ref, data);
    });
    return true;
  }

  async createNewTrial(trial: SimTacTrial): Promise<string> {
    const collectionRef = collection(this.firestore, 'trial');
    const firebaseObj = JSON.parse(JSON.stringify(trial));
    firebaseObj.endDate = trial.endDate;
    firebaseObj.startDate = trial.startDate;
    const docId = await addDoc(collectionRef, firebaseObj).then((d) => d.id);

    return docId;
  }

  async updateAddOnForUser(addOn: SimTacPurchase, userId): Promise<boolean> {
    const q = query(
      collection(this.firestore, 'purchased'),
      where('userId', '==', userId),
      where('addOnId', '==', addOn.addOnId)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      data = addOn;
      updateDoc(doc.ref, data);
    });
    return true;
  }

  async createNewAddOnForUser(addOn: SimTacPurchase): Promise<string> {
    const collectionRef = collection(this.firestore, 'purchased');
    const docId = await addDoc(
      collectionRef,
      JSON.parse(JSON.stringify(addOn))
    ).then((d) => d.id);

    return docId;
  }

  async createNewLiveSession(
    admins: string[],
    allUsers: string[],
    companyDomain: string,
    creatorEmail: string,
    creatorUserId: string,
    map: string,
    sessionName: string,
    viewers: string[],
    userId
  ) {
    const collectionRef = collection(this.firestore, 'sessions');
    const sessionId = crypto.randomUUID();

    const sessionDTO = new SimTacSession(
      admins,
      allUsers,
      companyDomain,
      creatorEmail,
      creatorUserId,
      true,
      map,
      sessionId,
      sessionName,
      viewers
    );

    await addDoc(collectionRef, JSON.parse(JSON.stringify(sessionDTO))).then(
      (d) => d.id
    );

    this.eventService.currentSession.next(sessionId);
    this.listenForEvents(userId);

    return sessionDTO;
  }

  async getLiveSessions(email: string) {
    const q = query(
      collection(this.firestore, 'sessions'),
      where('allUsers', 'array-contains', email)
    );
    const querySnapshot = await getDocs(q);
    const sessions: SimTacSession[] = [];
    querySnapshot.forEach((doc) => {
      if (doc.data().isActive) {
        sessions.push(doc.data() as SimTacSession);
      }
    });
    this.eventService.invitations.next(sessions);
    return sessions;
  }

  async updateLiveSession(userId: string) {
    const q = query(
      collection(this.firestore, 'sessions'),
      where('userId', '==', userId),
      where('isActive', '==', true)
    );
    const querySnapshot = await getDocs(q);
    const trials: SimTacTrial[] = [];
    querySnapshot.forEach((doc) => {
      trials.push(doc.data() as SimTacTrial);
    });
    return trials;
  }

  async inviteToLiveSession(emailAddresses: string[]) {
    return emailAddresses;
  }

  async updateUserLastLogin(userId) {
    const q = query(
      collection(this.firestore, 'users'),
      where('userId', '==', userId)
    );
    const querySnapshot = await getDocs(q);
    const users: SimTacUser[] = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      data.lastLogin = Timestamp.now();
      updateDoc(doc.ref, data);
      users.push(doc.data() as SimTacUser);
    });
    return users.length;
  }

  async getTotalNumberOfUsers() {
    const q = query(
      collection(this.firestore, 'users'),
      where('userId', '!=', null)
    );
    const querySnapshot = await getDocs(q);
    const users: SimTacUser[] = [];
    querySnapshot.forEach((doc) => {
      users.push(doc.data() as SimTacUser);
    });
    return users;
  }

  async getActiveNumberOfUsers() {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    const q = query(
      collection(this.firestore, 'users'),
      where('lastLogin', '>=', date)
    );
    const querySnapshot = await getDocs(q);
    const users: SimTacUser[] = [];
    querySnapshot.forEach((doc) => {
      users.push(doc.data() as SimTacUser);
    });
    return users;
  }

  async getMonthlyNumberOfUsers() {
    const date = new Date();
    date.setDate(date.getDate() - 30);
    const q = query(
      collection(this.firestore, 'users'),
      where('lastLogin', '>=', date)
    );
    const querySnapshot = await getDocs(q);
    const users: SimTacUser[] = [];
    querySnapshot.forEach((doc) => {
      users.push(doc.data() as SimTacUser);
    });
    return users;
  }

  async getUserList(userIds: string[]) {
    const date = new Date();
    date.setDate(date.getDate() - 30);
    const users: SimTacUser[] = [];

    while (userIds.length) {
      // firestore limits batches to 10
      const batch = userIds.splice(0, 10);
      const q = query(
        collection(this.firestore, 'users'),
        where('userId', 'in', batch)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        users.push(doc.data() as SimTacUser);
      });
    }
    return users;
  }

  async getActiveSubscriptions() {
    const date = new Date();
    const q = query(
      collection(this.firestore, 'premium'),
      where('endDate', '>=', date)
    );
    const querySnapshot = await getDocs(q);
    const subs: SimTacSubscription[] = [];
    querySnapshot.forEach((doc) => {
      if (doc.data().isActive) {
        subs.push(doc.data() as SimTacSubscription);
      }
    });
    return subs;
  }

  async getAllCloudSaves() {
    const q = query(collection(this.firestore, 'cloud-saves'));
    const querySnapshot = await getDocs(q);
    const subs: SimTacCloudSaveDetails[] = [];
    querySnapshot.forEach((doc) => {
      subs.push(doc.data() as SimTacCloudSaveDetails);
    });
    return subs;
  }

  async getTacticalAddOns() {
    const q = query(
      collection(this.firestore, 'purchased'),
      where('addOnId', '==', constants.TACTICAL_ADDON_ID)
    );
    const querySnapshot = await getDocs(q);
    const subs: SimTacPurchase[] = [];
    querySnapshot.forEach((doc) => {
      if (doc.data().isActive) {
        subs.push(doc.data() as SimTacPurchase);
      }
    });
    return subs;
  }

  async getHospitalAddOns() {
    const q = query(
      collection(this.firestore, 'purchased'),
      where('addOnId', '==', constants.HOSPITAL_ADDON_ID)
    );
    const querySnapshot = await getDocs(q);
    const subs: SimTacPurchase[] = [];
    querySnapshot.forEach((doc) => {
      if (doc.data().isActive) {
        subs.push(doc.data() as SimTacPurchase);
      }
    });
    return subs;
  }

  async getCampusAddOns() {
    const q = query(
      collection(this.firestore, 'purchased'),
      where('addOnId', '==', constants.CAMPUS_ADDON_ID)
    );
    const querySnapshot = await getDocs(q);
    const subs: SimTacPurchase[] = [];
    querySnapshot.forEach((doc) => {
      if (doc.data().isActive) {
        subs.push(doc.data() as SimTacPurchase);
      }
    });
    return subs;
  }

  async getFireEmsAddOns() {
    const q = query(
      collection(this.firestore, 'purchased'),
      where('addOnId', '==', constants.FIRE_EMS_ADDON_ID)
    );
    const querySnapshot = await getDocs(q);
    const subs: SimTacPurchase[] = [];
    querySnapshot.forEach((doc) => {
      if (doc.data().isActive) {
        subs.push(doc.data() as SimTacPurchase);
      }
    });
    return subs;
  }

  async getUserUploadedResources(userId: string) {
    const q = query(
      collection(this.firestore, 'resources'),
      where('userId', '==', userId)
    );
    const querySnapshot = await getDocs(q);
    const resources: SimTacResource[] = [];
    querySnapshot.forEach((doc) => {
        resources.push(doc.data() as SimTacResource);
    });
    return resources;
  }

  async saveUserUploadedResource(resource: SimTacResource) {
    const collectionRef = collection(this.firestore, 'resources');
    const docId = await addDoc(
      collectionRef,
      JSON.parse(JSON.stringify(resource))
    ).then(
      async (d) => {
        resource.resourceId = d.id;
        await updateDoc(d, JSON.parse(JSON.stringify(resource)));
      }
    );

    return docId;
  }

  async getActiveTrials() {
    const date = new Date();
    const q = query(
      collection(this.firestore, 'trial'),
      where('endDate', '>=', date)
    );
    const querySnapshot = await getDocs(q);
    const trials: SimTacTrial[] = [];
    querySnapshot.forEach((doc) => {
      trials.push(doc.data() as SimTacTrial);
    });
    return trials;
  }

  async getMonthlyNumberOfLiveSessions() {
    const date = new Date();
    date.setDate(date.getDate() - 30);
    const q = query(
      collection(this.firestore, 'sessions'),
      where('createdAt', '>=', date)
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.size;
  }

  async getUsersByIdOrEmail(searchQuery: string) {
    const q1 = query(
      collection(this.firestore, 'users'),
      where('userId', '>=', searchQuery),
      where('userId', '<=', searchQuery + '\uf8ff')
    );
    const q2 = query(
      collection(this.firestore, 'users'),
      where('email', '>=', searchQuery),
      where('email', '<=', searchQuery + '\uf8ff')
    );
    const querySnapshot1 = await getDocs(q1);
    const querySnapshot2 = await getDocs(q2);
    const users: SimTacUser[] = [];
    querySnapshot1.forEach((doc) => {
      users.push(doc.data() as SimTacUser);
    });
    querySnapshot2.forEach((doc) => {
      users.push(doc.data() as SimTacUser);
    });
    return users;
  }

  async updateUserAccess(userId, revoke = false) {
    const q = query(
      collection(this.firestore, 'users'),
      where("userId", "==", userId)
    );
    const querySnapshot = await getDocs(q);
    const users: SimTacUser[] = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      data.hasAccess = !revoke;
      updateDoc(doc.ref, data);
      users.push(doc.data() as SimTacUser);
    });
    return users.length;
  }
}
