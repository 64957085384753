import { Timestamp } from '@angular/fire/firestore';

export class LocalFile {
  fileName: string;
  name: string;
  fileCoordinates: FileCoordinates;
  fileId: string;
  path: string;
  data: string;
  type: string;
  flipped: boolean;
  flippedFileName: string;
  isPremiumItem: boolean;
  addOnParentId: string;
  font: SimTacFont;
  subText: string;
  subTextFont: SimTacFont;
  subTextMirror: boolean;

  constructor(
    name: string,
    fileName: string,
    path: string,
    fileCoordinates: FileCoordinates = new FileCoordinates(),
    data: string = '',
    type: string = '',
    flippedFileName: string = '',
    flipped: boolean = false,
    isPremiumItem: boolean = false,
    addOnParentId: string = '',
    font: SimTacFont = new SimTacFont(),
    subtext: string = "",
    subTextFont: SimTacFont = new SimTacFont(),
    subtextMirror: boolean = false,
  ) {
    this.name = name;
    this.fileName = fileName;
    this.fileCoordinates = fileCoordinates;
    this.path = path;
    this.data = data;
    this.type = type;
    this.flippedFileName = flippedFileName;
    this.flipped = flipped;
    this.isPremiumItem = isPremiumItem;
    this.addOnParentId = addOnParentId;
    this.font = font;
    this.subText = subtext;
    this.subTextFont = subTextFont;
    this.subTextMirror = this.subTextMirror;
  }
}

export class SimTacFont {
  font: string;
  color: string;
  fontSize: number;
  fontWeight: number;
  isItalic: boolean;

  constructor(
    font = '',
    color = '',
    fontSize = 25,
    fontWeight = 400,
    isItalic = false
  ) {
    this.font = font;
    this.fontSize = fontSize;
    this.color = color;
    this.fontWeight = fontWeight;
    this.isItalic = isItalic;
  }
}

export class Directory {
  url: string;
  folderName: string;
  files: LocalFile[];
  directories: Directory[];
  premiumContent: boolean;
  addOnParentId: string;

  constructor(
    url: string,
    folderName: string,
    files: LocalFile[],
    premiumContent: boolean = false,
    directories: Directory[] = [],
    addOnParentId: string = ''
  ) {
    this.url = url;
    this.folderName = folderName;
    this.files = files;
    this.premiumContent = premiumContent;
    this.directories = directories;
    this.addOnParentId = addOnParentId;
  }
}

export class FileCoordinates {
  x: number;
  y: number;
  initialx: number;
  initialy: number;
  transformx: number;
  transformy: number;
  transformz: number;
  height: number;
  width: number;
  zindex: number;
  rotate: number;

  constructor(
    x = 0,
    y = 0,
    transformx = 0,
    transformy = 0,
    transformz = 0,
    width = 75,
    height = 75,
    zindex = 1000,
    rotate = 0
  ) {
    this.x = x;
    this.y = y;
    this.initialx = x;
    this.initialy = y;
    this.transformx = transformx;
    this.transformy = transformy;
    this.transformz = transformz;
    this.height = height;
    this.width = width;
    this.zindex = zindex;
    this.rotate = rotate;
  }
}

export class SimTacMap {
  fileList: Map<string, LocalFile>;
  mapImage: LocalFile;
  mapDetails: SimTacMapDetails;
  locked: boolean;
  mapPassword: string;
  cloudSaveId: string;
  updatedAt: Timestamp;
  userId: string;

  constructor(
    fileList: Map<string, LocalFile>,
    mapImage: LocalFile,
    mapDetails: SimTacMapDetails,
    locked: boolean = false,
    mapPassword: string = '',
    cloudSaveId: string,
    updateAt: Timestamp = Timestamp.now(),
    userId: string
  ) {
    this.fileList = fileList;
    this.mapImage = mapImage;
    this.mapDetails = mapDetails;
    this.locked = locked;
    this.mapPassword = mapPassword;
    this.cloudSaveId = cloudSaveId;
    this.updatedAt = updateAt;
    this.userId = userId;
  }

  toDTO() {
    return new SimTacMapFlattened(this);
  }
}

export class SimTacMapDetails {
  mapName: string;
  mapAuthor: string;
  mapDescription: string;
  mapAuthorUrl: string;

  constructor(
    mapName: string,
    mapAuthor: string = '',
    mapDescription: string = '',
    mapAuthorUrl: string = ''
  ) {
    this.mapName = mapName;
    this.mapAuthor = mapAuthor;
    this.mapDescription = mapDescription;
    this.mapAuthorUrl = mapAuthorUrl;
  }
}

export class SimTacCloudSaveDetails {
  cloudSaveId: string;
  mapName: string;
  mapAuthor: string;
  mapAuthorUrl: string;
  mapDescription: string;
  mapImage: LocalFile;
  userId: string;
  createdAt: Timestamp;

  constructor(
    mapName: string,
    mapAuthor: string,
    mapDescription: string,
    mapAuthorUrl: string,
    userId: string,
    cloudSaveId: string = '',
    createdAt: Timestamp,
    mapImage: LocalFile
  ) {
    this.cloudSaveId = cloudSaveId;
    this.mapName = mapName;
    this.mapAuthor = mapAuthor;
    this.mapDescription = mapDescription;
    this.mapAuthorUrl = mapAuthorUrl;
    this.userId = userId;
    this.createdAt = createdAt;
    this.mapImage = mapImage;
  }
}

export class SimTacMapFlattened {
  fileList: string;
  mapImage: LocalFile;
  mapDetails: SimTacMapDetails;
  locked: boolean;
  mapPassword: string;
  cloudSaveId: string;
  updatedAt: Timestamp;
  userId: string;

  constructor(map: SimTacMap) {
    this.fileList = JSON.stringify(Array.from(map.fileList.entries()));
    this.mapImage = map.mapImage;
    if (this.mapImage.subTextMirror === undefined) {
      this.mapImage.subTextMirror = false;
    }
    this.mapDetails = map.mapDetails;
    this.locked = map.locked;
    this.mapPassword = map.mapPassword;
    this.cloudSaveId = map.cloudSaveId;
    this.updatedAt = map.updatedAt;
    this.userId = map.userId;
  }

  toModel() {
    return new SimTacMap(
      new Map(JSON.parse(this.fileList)) as Map<string, LocalFile>,
      this.mapImage,
      this.mapDetails,
      this.locked,
      this.mapPassword,
      this.cloudSaveId,
      this.updatedAt,
      this.userId
    );
  }
}

export class UndoRedoAction {
  action: string;
  file: LocalFile;

  constructor(action: string, file: LocalFile) {
    this.action = action;
    this.file = file;
  }
}
