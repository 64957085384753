/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, HostListener, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'delete-modal',
  templateUrl: 'delete-modal.html',
  styleUrls: ['delete-modal.scss'],
})
export class DeleteModal {
  @Input() deleteSelectedItem: any;
  constructor(private modalController: ModalController) {}

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.dismiss();
    }
    if (event.key === 'Enter') {
      this.deleteItem();
    }
  }

  deleteItem() {
    this.deleteSelectedItem();
    this.modalController.dismiss();
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
