/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { Component, OnInit } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment-error',
  templateUrl: './payment-error.page.html',
  styleUrls: ['./payment-error.page.scss'],
})
export class PaymentErrorPage implements OnInit {
  userAuthSubcription$: Subscription;
  public unsubscribe$ = new Subject<void>();

  constructor(private router: Router, public modalCtrl: ModalController) {}

  async ngOnInit() {}

  sendUserToPremiumPage() {
    this.router.navigateByUrl('/addons');
  }

  ngAfterViewInit() {}

  onOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
