/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  LoadingController,
  AlertController,
  MenuController,
  ModalController,
} from '@ionic/angular';
import {
  SimTacAddOn,
  SimTacOrderEmail,
  SimTacPurchase,
  SimTacSubscription,
} from '../models/firestore-models';
import { AuthService } from '../services/auth.service';
import { environment } from '../../environments/environment';
import { FirestoreService } from '../services/firestore.service';
import { StripeModal } from '../modals/stripe-modal/stripe-modal';
import { ShippingDetailsModal } from '../modals/shipping-details-modal/shipping-details-modal';

@Component({
  selector: 'app-add-ons',
  templateUrl: 'add-ons.html',
  styleUrls: ['add-ons.scss'],
})
export class AddOnsPage implements OnInit {
  public addons: SimTacAddOn[] = [];
  public purchases: SimTacPurchase[] = [];
  public subscriptions: SimTacSubscription[] = [];
  public filteredAddOns: SimTacAddOn[] = [];
  public filteredSubscriptions: SimTacAddOn[] = [];
  public filteredPurchases: SimTacAddOn[] = [];
  private asyncCallsCompleted = 0;
  private loader: any;
  public slideOpts = {
    initialSlide: 1,
    loop: true,
    autoHeight: true,
  };

  @ViewChild('purchasedSlides') purchasedSlides: any;
  @ViewChild('addOnsSlides') addOnsSlides: any;
  @ViewChild('subscriptionsSlides') subscriptionsSlides: any;

  constructor(
    private fireStoreService: FirestoreService,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private authService: AuthService,
    private router: Router,
    private menu: MenuController,
    private modalController: ModalController
  ) {}
  ngOnInit() {
    this.authService.userId.subscribe((userId) => {
      if (userId && userId !== '') {
        this.getAllAddOns();
        this.getPurchasedAddOns(userId);
        this.getUserSubscriptions(userId);
      }
    });
    this.loadingController
      .create({
        message: 'Loading add-ons...',
      })
      .then((loader) => {
        loader.present();
        this.loader = loader;
        setTimeout(() => {
          loader.dismiss();
        }, 2000);
      });
  }

  async slidePrevSubscriptions() {
    this.subscriptionsSlides.slidePrev();
  }
  async slideNextSubscriptions() {
    this.subscriptionsSlides.slideNext();
  }

  async slidePrevAddons() {
    this.addOnsSlides.slidePrev();
  }
  async slideNextAddons() {
    this.addOnsSlides.slideNext();
  }

  async slidePrevPurchased() {
    this.purchasedSlides.slidePrev();
  }
  async slideNextPurchased() {
    this.purchasedSlides.slideNext();
  }

  async filterPurchased() {
    this.addons.forEach((addon: SimTacAddOn) => {
      const found =
        this.purchases.filter(
          (purchase: SimTacPurchase) => addon.addOnId === purchase.addOnId
        ).length > 0;
      if (!found) {
        if (addon.isActive) {
          if (addon.addOnType === 'premium') {
            this.filteredSubscriptions.push(addon);
            this.filteredSubscriptions.sort(
              (a, b) => a.frequency.get('Monthly') - b.frequency.get('Monthly')
            );
          } else {
            this.filteredAddOns.push(addon);
          }
        }
      } else {
        this.filteredPurchases.push(addon);
      }
    });
    if (this.loader) {
      this.loader.dismiss();
    }
  }

  async getAllAddOns() {
    this.fireStoreService.getAllAddOns().then((addons: SimTacAddOn[]) => {
      this.addons = addons;
      this.asyncCallsCompleted++;
      if (this.asyncCallsCompleted >= 3) {
        this.filterPurchased();
      }
    });
  }

  async getPurchasedAddOns(userId) {
    this.fireStoreService
      .getPurchasesByUserId(userId)
      .then((purchases: SimTacPurchase[]) => {
        this.purchases = purchases;
        this.asyncCallsCompleted++;
        if (this.asyncCallsCompleted >= 3) {
          this.filterPurchased();
        }
      });
  }

  async getUserSubscriptions(userId) {
    this.fireStoreService
      .getSubscriptionsByUserId(userId)
      .then((purchases: SimTacSubscription[]) => {
        this.subscriptions = this.subscriptions;
        this.asyncCallsCompleted++;
        if (this.asyncCallsCompleted >= 3) {
          this.filterPurchased();
        }
      });
  }

  async showAlert(header, message) {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: ['OK'],
    });
    await alert.present();
  }

  purchaseClicked(addOn: SimTacAddOn, frequency: string) {
    let emailModel: SimTacOrderEmail;
    if (addOn.name.includes('Tactical') || addOn.name.includes('Hospital')) {
      this.presentShippingDetailsModal(addOn, frequency);
    } else {
      this.presentStripeModal(addOn, frequency, emailModel);
    }
  }

  async presentStripeModal(
    addOn: SimTacAddOn,
    frequency: string,
    emailModel: SimTacOrderEmail
  ) {
    const modal = await this.modalController.create({
      component: StripeModal,
      cssClass: 'stripe-modal',
      componentProps: {
        addOnToPurchase: addOn,
        frequency,
        emailModel,
      },
    });
    await modal.present();
    return modal;
  }

  async presentShippingDetailsModal(addOn: SimTacAddOn, frequency: string) {
    const modal = await this.modalController.create({
      component: ShippingDetailsModal,
      cssClass: 'auto-height',
      componentProps: {
        detailsCaptured: (
          firstLast: string,
          addr1: string,
          addr2: string,
          ctyStZip: string,
          email: string
        ) => {
          const shippingDetails = new SimTacOrderEmail(
            firstLast,
            addr1,
            addr2,
            ctyStZip,
            email,
            addOn.name
          );
          this.modalController.dismiss();
          this.presentStripeModal(addOn, frequency, shippingDetails);
        },
      },
    });
    await modal.present();
    return modal;
  }

  paymentClicked() {}

  homeClicked() {
    this.menu.close();
    this.router.navigateByUrl('map-selection');
  }

  liveSessionClicked() {
    this.alertController
      .create({
        message: 'Live sessions are coming in a future update!',
        buttons: [
          {
            text: 'OK',
            role: 'confirm',
            handler: () => {
              this.router.navigateByUrl('map-selection');
            },
          },
        ],
      })
      .then((alert) => {
        alert.present();
      });
  }

  invitesClicked() {
    this.alertController
      .create({
        message: 'Invites are coming in a future update!',
        buttons: [
          {
            text: 'OK',
            role: 'confirm',
            handler: () => {
              this.router.navigateByUrl('map-selection');
            },
          },
        ],
      })
      .then((alert) => {
        alert.present();
      });
  }

  aboutClicked() {
    this.alertController
      .create({
        message: 'Current Version: ' + environment.version,
        buttons: [
          {
            text: 'OK',
            role: 'confirm',
            handler: () => {
              this.router.navigateByUrl('map-selection');
            },
          },
        ],
      })
      .then((alert) => {
        alert.present();
      });
  }

  logoutClicked() {
    this.alertController
      .create({
        message: 'Logout?',
        buttons: [
          {
            text: 'cancel',
            role: 'cancel',
            handler: () => {},
          },
          {
            text: 'logout',
            role: 'confirm',
            handler: () => {
              this.authService.logout().then(() => {
                this.menu.close();
                this.router.navigateByUrl('/');
                window.location.reload();
              });
            },
          },
        ],
      })
      .then((alert) => {
        alert.present();
      });
  }

  trimString(str, length) {
    return str.length > length ? str.substring(0, length) + '...' : str;
  }
}
