/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, Input } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';

@Component({
  selector: 'password-modal',
  templateUrl: 'password-modal.html',
  styleUrls: ['password-modal.scss'],
})
export class PasswordModal {
  @Input() currentPassword: string;
  @Input() passwordSuccess: any;

  public passwordValue: string;

  constructor(
    private modalController: ModalController,
    private alertController: AlertController
  ) {}

  checkPassword() {
    if (this.passwordValue !== this.currentPassword) {
      this.alertController
        .create({
          message: 'Incorrect Password',
          buttons: [
            {
              text: 'Ok',
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
      return;
    }
    this.passwordSuccess();
    this.modalController.dismiss();
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
