/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, Input } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'forgot-password-modal',
  templateUrl: 'forgot-password-modal.html',
  styleUrls: ['forgot-password-modal.scss'],
})
export class ForgotPasswordModal {
  @Input() onEmailSent: any;

  public emailValue: string;

  constructor(
    private modalController: ModalController,
    private alertController: AlertController,
    private authService: AuthService
  ) {}

  async checkEmail() {
    if (!this.emailValue || this.emailValue === '') {
      this.alertController
        .create({
          message: 'Invalid Email',
          buttons: [
            {
              text: 'Ok',
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
      return;
    }
    await this.authService.sendPasswordResetEmail(this.emailValue);
    this.onEmailSent();
    this.modalController.dismiss();
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
