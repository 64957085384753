/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable eqeqeq */
/* eslint-disable @angular-eslint/use-lifecycle-interface */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { SimTacMapDetails } from 'src/app/models/local-files';
import { EventService } from 'src/app/services/event-service';
import { FileService } from 'src/app/services/file-service';
import { DeleteModal } from '../delete-modal/delete-modal';
import { constants } from '../../constants/constants';
import { SimTacDigitalProfile } from 'src/app/models/firestore-models';

@Component({
  selector: 'map-meta-data-modal',
  templateUrl: 'map-meta-data-modal.html',
  styleUrls: ['map-meta-data-modal.scss'],
})
export class MapMetaDataModal {
  @Input() saveMetaData: any;
  @Input() currentMetaData: SimTacMapDetails;
  @Input() isMapLocked: boolean;
  @Input() mapLockedPassword: string;
  @Input() mapType: string;
  @Input() digitalProfile: SimTacDigitalProfile;

  mapName = '';
  mapDescription = '';
  mapAuthor = '';
  authorUrl = '';
  lockedMap = false;
  lockPassword = '';
  lessonPlanURL = '';
  tacticalPlanUrl = constants.TACTICAL_LESSON_PLAN;

  constructor(
    private modalController: ModalController,
    private eventService: EventService,
    private fileService: FileService,
    private router: Router,
    private alertController: AlertController
  ) {}

  ngOnInit() {
    this.mapName = this.currentMetaData.mapName;
    this.mapDescription = this.currentMetaData.mapDescription;
    this.mapAuthor = this.currentMetaData.mapAuthor;
    this.authorUrl = this.currentMetaData.mapAuthorUrl;
    this.lockedMap = this.isMapLocked;
    this.lockPassword = this.mapLockedPassword;
    if (this.mapType) {
      switch (this.mapType) {
        case 'school':
          this.lessonPlanURL = constants.SCHOOL_LESSON_PLAN;
          break;
        case 'hospital':
          this.lessonPlanURL = constants.HOSPITAL_LESSON_PLAN;
          break;
        case 'campus':
          this.lessonPlanURL = constants.CAMPUS_LESSON_PLAN;
          break;
        case 'fire/ems':
          this.lessonPlanURL = constants.FIRE_EMS_LESSON_PLAN;
          break;
        case 'theater':
          this.lessonPlanURL = constants.THEATER_LESSON_PLAN;
          break;
        case 'city':
          this.lessonPlanURL = constants.SIMTAC_CITY_LESSON_PLAN;
          break;
        case 'school.jpg':
          this.lessonPlanURL = constants.SCHOOL_LESSON_PLAN;
          break;
        case 'hospital.jpg':
          this.lessonPlanURL = constants.HOSPITAL_LESSON_PLAN;
          break;
        case 'Campus.png':
          this.lessonPlanURL = constants.CAMPUS_LESSON_PLAN;
          break;
        case 'FireEms.png':
          this.lessonPlanURL = constants.FIRE_EMS_LESSON_PLAN;
          break;
        case 'theater.jpg':
          this.lessonPlanURL = constants.THEATER_LESSON_PLAN;
          break;
        case 'simtaccity.jpg':
          this.lessonPlanURL = constants.SIMTAC_CITY_LESSON_PLAN;
          break;
      }
    }
  }

  save() {
    if (this.lockedMap && this.lockPassword == '') {
      this.alertController
        .create({
          message: 'A locked map must have a password.',
          buttons: [
            {
              text: 'Ok',
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
      return;
    }
    if (this.mapName == '') {
      this.alertController
        .create({
          message: 'A map must have a name.',
          buttons: [
            {
              text: 'Ok',
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
      return;
    }
    this.saveMetaData(
      this.mapName,
      this.mapDescription,
      this.mapAuthor,
      this.authorUrl,
      this.lockedMap,
      this.lockPassword
    );
    this.modalController.dismiss();
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  async presentDeleteModal() {
    const modal = await this.modalController.create({
      component: DeleteModal,
      cssClass: 'delete-modal',
      componentProps: {
        deleteSelectedItem: async () => {
          console.log(
            'Deleting map: ' + this.eventService.currentMapDetails.value.mapName
          );
          await this.fileService
            .deleteMap(this.eventService.currentMapDetails.value.mapName)
            .then(async () => {
              this.eventService.resetData();
              this.eventService.resetMapData();
              this.fileService.getAllMaps();
              this.router.navigateByUrl('map-selection');
              await this.modalController.dismiss();
              this.modalController.getTop().then((modal) => {
                if (modal) {
                  modal.dismiss();
                }
              });
            });
        },
      },
    });
    return await modal.present();
  }
}
