/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { Component, OnInit } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { ModalController, Platform, LoadingController } from '@ionic/angular';
import { takeUntil, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import {
  SimTacPurchase,
  SimTacSubscription,
} from 'src/app/models/firestore-models';
import { EventService } from 'src/app/services/event-service';
import { Timestamp } from '@angular/fire/firestore';

@Component({
  selector: 'app-success',
  templateUrl: './success.page.html',
  styleUrls: ['./success.page.scss'],
})
export class SuccessPage implements OnInit {
  userAuthSubcription$: Subscription;
  public unsubscribe$ = new Subject<void>();

  isLoading = false;
  showErrorMessage = false;
  retries = 0;

  constructor(
    private router: Router,
    private firestoreService: FirestoreService,
    private loadingController: LoadingController,
    private authService: AuthService,
    public modalCtrl: ModalController,
    private eventService: EventService
  ) {}

  async ngOnInit() {
    this.isLoading = true;
    await this.showLoader();
    this.getUserPurchasesWithRetry();
  }

  async getUserPurchasesWithRetry() {
    if (this.retries <= 3) {
      if (this.eventService.purchasingAddonType.value === 'addon') {
        this.firestoreService
          .getPurchasesByUserId(this.authService.userId.value)
          .then((purchases: SimTacPurchase[]) => {
            let found = false;
            purchases.forEach((purchase) => {
              if (
                purchase.addOnId === this.eventService.purchasingAddonId.value
              ) {
                found = true;
              }
            });
            if (!found) {
              this.retries++;
              this.sleep(1000).then(() => {
                this.getUserPurchasesWithRetry();
              });
            } else {
              this.authService.userPurchases.next(purchases);
              this.retries = 0;
              this.userIsPremium();
            }
          });
      } else {
        this.firestoreService
          .getSubscriptionsByUserId(this.authService.userId.value)
          .then((purchases: SimTacSubscription[]) => {
            let found = false;
            purchases.forEach((purchase) => {
              if (
                purchase.endDate >= Timestamp.now() &&
                purchase.addOnId === this.eventService.purchasingAddonId.value
              ) {
                found = true;
              }
            });
            if (!found) {
              this.retries++;
              this.sleep(1000).then(() => {
                this.getUserPurchasesWithRetry();
              });
            } else {
              this.authService.userSubscriptions.next(purchases);
              this.retries = 0;
              this.userIsPremium();
            }
          });
      }
    } else {
      this.retries = 0;
      this.userIsNotPremium();
    }
  }

  async userIsPremium() {
    await this.sleep(4000);
    this.router.navigateByUrl('/tabs');
  }

  async userIsNotPremium() {
    this.dismissLoader();
    this.showErrorMessage = true;
    this.isLoading = false;
  }

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async showLoader() {
    const loading = await this.loadingController.create({
      spinner: 'circles',
      duration: 0,
      message: 'Please wait while we verify your account status...',
      translucent: true,
      cssClass: 'overview-loading',
    });
    setTimeout(() => {
      this.isLoading = false;
      this.dismissLoader();
      setTimeout(() => {
        this.router.navigateByUrl('map-selection');
      }, 2000);
    }, 5000);
    return await loading.present();
  }

  async dismissLoader() {
    const currentLoader = await this.loadingController.getTop();
    if (currentLoader) {
      currentLoader.dismiss();
    }
  }

  sendUserToPremiumPage() {
    this.router.navigateByUrl('/addons');
  }

  ngAfterViewInit() {}

  onOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
