import { Component, NgZone } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SplashScreen } from '@capacitor/splash-screen';
import { Platform } from '@ionic/angular';
import { App, URLOpenListenerEvent } from '@capacitor/app';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  mySubscription;

  constructor(
    private platform: Platform,
    private router: Router,
    private zone: NgZone
  ) {
    this.showSplashscreen();
    this.platform.ready().then(() => {
      SplashScreen.hide();
    });
    this.initializeApp();
    this.router.navigate(['']);
  }

  async showSplashscreen() {
    await SplashScreen.show({
      showDuration: 10000,
      autoHide: false,
    });
  }

  initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const slug = event.url.split('.com').pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
      });
    });
  }
}
