/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/use-lifecycle-interface */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  LoadingController,
  AlertController,
  ModalController,
} from '@ionic/angular';
// import { Stripe } from '@ionic-native/stripe/ngx'
import { HttpClient } from '@angular/common/http';
declare let Stripe;
import { loadStripe } from '@stripe/stripe-js';
import { SimTacAddOn, SimTacOrderEmail } from '../models/firestore-models';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-stripe',
  templateUrl: 'stripe.page.html',
  styleUrls: ['stripe.page.scss'],
})
export class StripePage {
  paymentAmount = 199.99;
  currency = 'USD';
  currencyIcon = '$';
  stripeKey = '';
  cardDetails: any = {};
  stripe: any;
  card: any;
  @Input() addOnToPurchase: SimTacAddOn;
  @Input() frequency: string;
  @Input() emailModel: SimTacOrderEmail;
  frequencyVerbiage = '';

  constructor(
    // private stripe: Stripe,
    private http: HttpClient,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private authService: AuthService,
    private router: Router,
    private modalController: ModalController
  ) {}

  payWithStripe() {
    this.stripe.setPublishableKey(this.stripeKey);

    this.cardDetails = {
      cardNumber: '4242424242424242',
      expMonth: 12,
      expYear: 2025,
      cvc: '220',
    };

    this.stripe
      .createCardToken(this.cardDetails)
      .then((token) => {
        console.log(token);
        this.makePayment(token.id);
      })
      .catch((error) => console.error(error));
  }

  async ngOnInit() {
    this.stripe = await loadStripe('pk_live_CM3IrnMOQHtxC0NOIQNateJw');
    this.paymentAmount = this.addOnToPurchase.frequency.get(this.frequency);
    this.frequencyVerbiage =
      this.frequency === 'oneTime'
        ? 'One Time'
        : this.frequency === 'Annually'
        ? 'Annual'
        : 'Monthly';
    // this.setupStripe();
  }

  //  setupStripe() {
  //    let elements = this.stripe.elements();
  //    var style = {
  // 	 base: {
  // 	   color: '#32325d',
  // 	   lineHeight: '24px',
  // 	   fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
  // 	   fontSmoothing: 'antialiased',
  // 	   fontSize: '16px',
  // 	   '::placeholder': {
  // 		 color: '#aab7c4'
  // 	   }
  // 	 },
  // 	 invalid: {
  // 	   color: '#fa755a',
  // 	   iconColor: '#fa755a'
  // 	 }
  //    };

  //    this.card = elements.create('card', { style: style });
  //    console.log(this.card);
  //    this.card.mount('#card-element');

  //    this.card.addEventListener('change', event => {
  // 	 var displayError = document.getElementById('card-errors');
  // 	 if (event.error) {
  // 	   displayError.textContent = event.error.message;
  // 	 } else {
  // 	   displayError.textContent = '';
  // 	 }
  //    });

  //    var form = document.getElementById('payment-form');
  //    form.addEventListener('submit', event => {
  // 	 event.preventDefault();
  // 	 console.log(event)

  // 	 this.stripe.createSource(this.card).then(result => {
  // 	   if (result.error) {
  // 		 var errorElement = document.getElementById('card-errors');
  // 		 errorElement.textContent = result.error.message;
  // 	   } else {
  // 		 console.log(result);
  // 		 this.makePayment(result.id);
  // 	   }
  // 	 });
  //    });
  //  }

  firebaseURL =
    'https://us-central1-sim-tac-functions.cloudfunctions.net/payWithStripe';
  nodeURL = 'http://localhost:3000';
  makePayment(token) {
    this.http
      .post(this.firebaseURL, {
        amount: 100,
        currency: 'usd',
        token: token.id,
      })
      .subscribe((data) => {
        console.log(data);
      });
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngAfterViewInit() {
    document.querySelector('#submit').addEventListener('click', (evt) => {
      evt.preventDefault();
      // Initiate payment
      this.createCheckoutSession(
        this.addOnToPurchase.name,
        this.frequency,
        this.addOnToPurchase.addOnId,
        this.emailModel
      ).then((response) => {
        this.stripe
          .redirectToCheckout({
            sessionId: response.checkoutSessionId,
          })
          .then(() => {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
          })
          .catch((err) => {
            console.log(err);
          });
      });
    });
  }

  async showLoader(msg?) {
    const loading = await this.loadingController.create({
      spinner: 'circles',
      duration: 0,
      message: msg ? msg : 'Please wait while we contact Stripe...',
      translucent: true,
      cssClass: 'overview-loading',
    });
    return await loading.present();
  }

  async dismissLoader() {
    const currentModal = await this.modalController.getTop();
    const currentLoader = await this.loadingController.getTop();
    console.log('AAHHHHHHH');
    console.log('WE ARE HERE');
    if (currentLoader) {
      await currentLoader.dismiss();
    }
    if (currentModal) {
      await currentModal.dismiss();
    }
  }

  createCheckoutSession(
    purchaseType: string,
    purchaseFrequency: string,
    addOnId: string,
    emailModel: SimTacOrderEmail
  ) {
    try {
      const body = {
        userId: this.authService.userId.value,
        trialPeriodInDays: 7,
        purchaseType,
        purchaseFrequency,
        addOnId,
        emailModel,
        // successUrl:
        // 'http://localhost:8100/payment/success-redirect',
        // errorUrl: 'http://localhost:8100/payment/payment-error',
        successUrl: 'https://app.simtaccity.com/payment/success-redirect',
        errorUrl: 'https://app.simtaccity.com/payment/payment-error',
      };
      this.showLoader();
      return fetch(
        'https://us-central1-simtac-dms.cloudfunctions.net/createCheckoutSession',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        }
      )
        .then((response) => {
          this.dismissLoader();
          this.router.navigateByUrl('payment/success');
          return response.json();
        })
        .catch((ex) => {
          console.log(ex);
          this.dismissLoader();
          this.displayErrorPopUp();
        });
    } catch (error) {
      this.dismissLoader();
      this.displayErrorPopUp();
    }
  }

  async displayErrorPopUp(
    errorHeader?: string,
    errorSubtitle?: string,
    errorMsg?: string
  ) {
    if (!errorMsg) {
      errorMsg = 'Something went wrong, please try again!';
    }
    if (!errorHeader) {
      errorHeader = 'Uh oh';
    }
    if (!errorSubtitle) {
      errorSubtitle = 'Error';
    }
    const alert = await this.alertController.create({
      cssClass: 'alert-error-class',
      header: errorHeader,
      subHeader: errorSubtitle,
      message: errorMsg,
      buttons: ['OK'],
    });

    await alert.present();
  }
}
