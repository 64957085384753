/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, Input } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'shipping-details-modal',
  templateUrl: 'shipping-details-modal.html',
  styleUrls: ['shipping-details-modal.scss'],
})
export class ShippingDetailsModal {
  @Input() detailsCaptured: any;

  constructor(
    private modalController: ModalController,
    private alertController: AlertController,
    private fb: FormBuilder
  ) {}

  // eslint-disable-next-line @typescript-eslint/member-ordering
  shippingForm = this.fb.group({
    first: ['', Validators.compose([Validators.required])],
    last: ['', Validators.compose([Validators.required])],
    addressLine1: ['', Validators.compose([Validators.required])],
    addressLine2: [''],
    city: ['', Validators.compose([Validators.required])],
    state: ['', Validators.compose([Validators.required])],
    zip: ['', Validators.compose([Validators.required])],
    email: ['', Validators.compose([Validators.required, Validators.email])],
  });

  get first() {
    return this.shippingForm.get('first');
  }
  get last() {
    return this.shippingForm.get('last');
  }
  get addressLine1() {
    return this.shippingForm.get('addressLine1');
  }
  get addressLine2() {
    return this.shippingForm.get('addressLine2');
  }
  get city() {
    return this.shippingForm.get('city');
  }
  get state() {
    return this.shippingForm.get('state');
  }
  get zip() {
    return this.shippingForm.get('zip');
  }
  get email() {
    return this.shippingForm.get('email');
  }

  checkDetails() {
    console.log(this.shippingForm);
    if (!this.shippingForm.valid) {
      this.alertController
        .create({
          message: 'Please fill out required fields.',
          buttons: [
            {
              text: 'Ok',
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
      return;
    }

    this.detailsCaptured(
      this.shippingForm.value.first.trim() +
        ' ' +
        this.shippingForm.value.last.trim(),
      this.shippingForm.value.addressLine1.trim(),
      !this.shippingForm.value.addressLine2 ||
        this.shippingForm.value.addressLine2.trim() === ''
        ? 'N/A'
        : this.shippingForm.value.addressLine2.trim(),
      this.shippingForm.value.city.trim() +
        ', ' +
        this.shippingForm.value.state.trim() +
        ' ' +
        this.shippingForm.value.zip.trim(),
      this.shippingForm.value.email.trim()
    );
    this.modalController.dismiss();
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  trackByFn(index: any, item: any) {
    return index;
  }
}
