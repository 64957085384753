import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'rename-modal',
  templateUrl: 'rename-modal.html',
  styleUrls: ['rename-modal.scss']
})
export class RenameModal {
  @Input() renameSelectedItem: any;
  public renameValue: string;

  constructor(private modalController: ModalController) {}

  renameItem() {
    this.renameSelectedItem(this.renameValue);
    this.modalController.dismiss();
  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }
}